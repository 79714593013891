import type { PhotoSize } from 'api';
import { Video } from 'api';
import { Text } from 'components/@tedui';
import AnimatedIconButton from 'components/AnimatedIconButton';
import Image from 'components/image';
import { Link } from 'components/router';
import { formatDistanceToNowStrict } from 'date-fns';
import mixpanel from 'lib/analytics/mixpanel';
import { useAuthenticated } from 'lib/auth';
import { ViewCount } from 'lib/business';
import getImageFromSet from 'lib/get-image-from-set';
import { useOptimizedImage } from 'lib/hooks/useOptimizedImage';

import { useIntl } from 'react-intl';

interface HomepageSpotlightProps {
  spotLightData: Video;
}

const HomepageSpotlight = ({ spotLightData }: HomepageSpotlightProps) => {
  const { formatNumber } = useIntl();
  const loggedIn = useAuthenticated();

  const featuredImage = getImageFromSet(
    spotLightData?.primaryImageSet as PhotoSize[],
    '16x9'
  );
  const { src, blurredSrc } = useOptimizedImage({
    imageUrl: featuredImage ?? '',
    width: 1200,
    height: 675
  });

  const linkUrl = `/talks/${spotLightData.slug}`;

  const topic = spotLightData?.topics?.nodes?.[0]?.name;
  const spotLightDataDuration = Math.floor(spotLightData.duration / 60);

  return (
    <div className="grid w-full grid-cols-2 gap-10 pb-10">
      <div className="flex flex-col justify-center">
        <div className="mb-2 mt-3 flex items-center justify-between">
          <Link
            href={`/talks?sort=relevance&topics%5B0%5D=${topic}`}
            isZenithLink
            onClick={() =>
              mixpanel.track('homepage_click', {
                current_url: window.location.href,
                title: 'editorial-section',
                item_number: '1',
                link_type: 'topiic',
                value: ''
              })
            }
          >
            <div className="mt-3 lg-tui:mt-0">
              <Text
                variant="label1"
                tag="p"
                weight="font-semibold"
                color={{ override: '#EB0028' }}
              >
                {topic}
              </Text>
            </div>
          </Link>
          {loggedIn && (
            <a id={'spotlight-feature-anchor'}>
              <AnimatedIconButton
                talk={spotLightData}
                mixpanelTitle={'editorial-section'}
                itemNumber={'1'}
              />
            </a>
          )}
        </div>

        <Link
          href={`${linkUrl}`}
          isZenithLink
          onClick={() =>
            mixpanel.track('homepage_click', {
              current_url: window.location.href,
              title: 'editorial-section',
              item_number: '1',
              link_type: 'title',
              value: ''
            })
          }
        >
          <Text
            variant="header3"
            useNewTextStyles
            tag="p"
            weight="font-semibold"
            UNSAFE_className="mt-2"
          >
            {spotLightData?.title}
          </Text>
          <div className="max-h-[100px] overflow-hidden">
            <Text
              variant="body2"
              useNewTextStyles
              tag="p"
              UNSAFE_className="mt-2 line-clamp-5"
            >
              {spotLightData?.description}
            </Text>
          </div>

          <div className="mt-2">
            <Text tag="p" variant="caption1" useNewTextStyles>
              {spotLightData?.presenterDisplayName}
            </Text>

            <Text tag="p" variant="caption1">
              <ViewCount count={spotLightData!.viewedCount}>
                {formatNumber(spotLightData!.viewedCount, {
                  notation: 'compact',
                  compactDisplay: 'short'
                })}{' '}
                plays ·{' '}
              </ViewCount>
              {formatDistanceToNowStrict(new Date(spotLightData!.publishedAt))}{' '}
              ago
            </Text>
          </div>
        </Link>
      </div>

      <Link
        href={`${linkUrl}`}
        isZenithLink
        onClick={() =>
          mixpanel.track('homepage_click', {
            current_url: window.location.href,
            title: 'editorial-section',
            item_number: '1',
            link_type: 'image',
            value: ''
          })
        }
      >
        <div className="relative">
          <div className="aspect-h-9 aspect-w-16">
            {src && (
              <Image
                alt={spotLightData.slug ?? ''}
                src={src}
                placeholder="blur"
                blurDataURL={blurredSrc}
                fill
                className="object-cover"
              />
            )}
          </div>

          <Text
            variant="unset"
            UNSAFE_className="text-tui-xs text-white absolute bottom-2 right-2 font-bold bg-textSecondary-onLight px-1 rounded-xs uppercase leading-[20.7px] -tracking-[0.72px]"
            tag="p"
          >
            {spotLightDataDuration} min
          </Text>
        </div>
      </Link>
    </div>
  );
};

export default HomepageSpotlight;
