import { crush } from '@tedconf/js-crushinator-helpers';
import cx from 'classnames';
import { Icon, Text } from 'components/@tedui';
import Image from 'components/image';
import { useMemo } from 'react';
import { CardState } from './NewsletterRibbonForm.props';

interface SubscribeCardProps {
  card: CardState;
  onClick: () => void;
  hideSeparator?: boolean;
  testId?: string;
}

const SubscribeCard = ({
  testId = '',
  card,
  onClick,
  hideSeparator
}: SubscribeCardProps) => {
  const isToggled = card.isSubscribed || card.isSelected;
  const buttonStyle = cx(
    'mt-5 flex items-center justify-center gap-2 rounded-sm py-2',
    {
      'border-thin border-gray-400 bg-transparent': isToggled,
      'bg-white py-[9px]': !isToggled
    }
  );

  const buttonTextStyle = cx(
    'capitalize',
    isToggled ? 'text-white' : 'text-black'
  );

  const wrapperStyle = cx(
    'mx-auto flex w-auto flex-1 flex-col justify-between px-5 first:ml-0 first:pl-0 last:border-none last:pr-0 lg-tui:last:pr-5',
    {
      'border-r-thin border-gray-700': !hideSeparator
    }
  );

  function getCardStatus(card: {
    isSubscribed: boolean;
    isSelected: boolean;
  }): string {
    if (card.isSubscribed) {
      return 'Subscribed';
    }

    if (card.isSelected) {
      return 'Selected';
    }

    return 'Select';
  }

  const url = useMemo(() => crush(card.img), [card.img]);

  return (
    <div className={wrapperStyle}>
      <div>
        <Text
          color={{ isOnLightSurface: false }}
          isBold
          variant="body2"
          direction="ltr"
        >
          {card.period.toUpperCase()}
        </Text>
        <div className="my-3">
          <Image src={url} alt={card.newsletter_id} width={200} height={200} />
        </div>
        <div className="flex h-16 flex-col">
          <Text
            color={{ isOnLightSurface: false }}
            isBold
            variant="body1"
            direction="ltr"
          >
            {card.title}
          </Text>
          <Text
            color={{ isOnLightSurface: false }}
            variant="body2"
            direction="ltr"
          >
            {card.subtitle}
          </Text>
        </div>
      </div>

      <button
        data-testid={testId}
        disabled={card.isSubscribed}
        type="button"
        onClick={onClick}
        className={buttonStyle}
      >
        <Text
          useNewTextStyles
          tag="p"
          variant="label2"
          weight="font-bold"
          UNSAFE_className={buttonTextStyle}
        >
          {getCardStatus(card)}
        </Text>
        {isToggled ? (
          <Icon
            iconName="check-circle"
            className="mr-2 text-tui-xl font-bold text-white"
          />
        ) : (
          <Icon
            iconName="plus-circle"
            className="mr-2 text-tui-xl font-bold text-black"
          />
        )}
      </button>
    </div>
  );
};

export default SubscribeCard;
