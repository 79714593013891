import { SailthruSections } from 'lib/sailthru';

export const sailthruDataSections = [
  {
    utmTerm: 'homepage_aitrending-ideas',
    mixpanelTitle: 'trending-ai',
    title: 'AI',
    headerText:
      'Gain a deeper understanding of AI and its impact on our world — uncover its potential, and feel inspired to tackle this transformative field.',
    headerLink:
      '/talks?sort=relevance&topics%5B0%5D=ai&utm_source=homepage_aitrending-ideas&utm_content=0&utm_term=browse-all',
    headerLinkText: 'Browse all AI',
    contentType: SailthruSections.RecommendationsAI
  },
  {
    utmTerm: 'homepage_ted-edtrending-ideas',
    mixpanelTitle: 'trending-ted-ed',
    title: 'TED-Ed',
    headerText:
      "TED-Ed's captivating video animations ignite curiosity and make complex topics irresistibly engaging. Come scratch your itch to learn.",
    headerLink:
      '/talks?sort=relevance&topics%5B0%5D=ted-ed&utm_source=homepage_ted-edtrending-ideas&utm_content=0&utm_term=browse-all',
    headerLinkText: 'Browse all TED-Ed animations',
    contentType: SailthruSections.RecommendationsTEDEd
  },
  {
    utmTerm: 'homepage_psychologytrending-ideas',
    mixpanelTitle: 'trending-psychology',
    title: 'Psychology',
    headerText:
      'Grab a front-row seat to unravelling the mysteries of the human mind and embark on a journey of self-discovery.',
    headerLink:
      '/talks?sort=relevance&topics%5B0%5D=psychology&utm_source=homepage_psychologytrending-ideas&utm_content=0&utm_term=browse-all',
    headerLinkText: 'Browse all Psychology',
    contentType: SailthruSections.RecommendationsPsychology
  }
];
