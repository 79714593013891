import { Fragment, useCallback } from 'react';
import { SailthruNodesData, SlotData } from '../EditorialGrid.props';
import { EditorialGridItem } from '../EditorialGridItem';

const EditorialTwoColumnsWithImage = ({
  primary,
  secondary,
  tertiary,
  mixpanelTitle
}: SlotData) => {
  const renderTertiaryItems = useCallback(
    (items: SailthruNodesData[], sumNumber: number) =>
      items.map((item, index) => (
        <Fragment key={item.slug}>
          <EditorialGridItem
            contentIndex={index + sumNumber}
            slot="tertiary"
            data={item}
            wrapperClassName="pb-5 first:border-b-thin"
            mixpanelTitle={mixpanelTitle as string}
          />
        </Fragment>
      )),
    []
  );

  return (
    <>
      {/* 1023 - 768 */}
      <div
        id="editorial-grid-two-with-image-columns"
        data-testid="editorial-grid-two-with-image-columns"
        className="invisible h-0 md-tui:visible md-tui:h-min lg-tui:invisible lg-tui:h-0"
      >
        <div className="-ml-5 grid grid-cols-2 gap-5 divide-x-thin pb-8">
          <div className="pl-5">
            <EditorialGridItem
              slot="primary"
              data={primary}
              contentIndex={1}
              mixpanelTitle={mixpanelTitle as string}
            />
          </div>
          <div className="pl-5">
            <EditorialGridItem
              slot="secondary"
              data={secondary}
              contentIndex={2}
              mixpanelTitle={mixpanelTitle as string}
            />
          </div>
        </div>

        <div className="grid grid-cols-2">
          <div className="flex flex-col gap-5 pr-5">
            {renderTertiaryItems(tertiary.slice(0, 2), 3)}
          </div>

          <div className="relative">
            <div className="absolute left-0 top-1/2 h-[88%] -translate-y-1/2 transform border-l-thin" />
            <div className="flex flex-col gap-5 pl-5">
              {renderTertiaryItems(tertiary.slice(2, 4), 5)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditorialTwoColumnsWithImage;
