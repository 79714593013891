import { Slot, Video } from 'api';
import cx from 'classnames';
import { Text } from 'components/@tedui';
import ContentContainer from 'components/content-container';
import { Link } from 'components/router';
import { useDidMount } from 'lib/hooks/useDidMount';
import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';
import usePersonalizedContent from 'lib/hooks/useSailthru/usePersonalizedContent';
import { isEmpty, orderBy, sortBy } from 'lodash-es';
import { useEffect, useMemo } from 'react';

import StandardCardSkeleton from './Skeleton';
import StandardCard from './StandardCard';

interface SailthruContentProps {
  video: Partial<Video>;
}

interface StandardRibbonProps {
  data: Partial<Video>[] | SailthruContentProps[] | Slot[];
  title: string;
  linkText?: string;
  linkUrl?: string;
  sailthruSectionId?: string;
  mixpanelTitle: string;
  isSailthru?: boolean;
  isProgrammer?: boolean;
  hasLink?: boolean;
  hasSailthruCookie?: boolean;
  fallbackData?: Partial<Video>[] | SailthruContentProps[] | Slot[];
}

const StandardRibbon = ({
  data,
  title,
  linkText = null,
  linkUrl = null,
  isSailthru,
  isProgrammer,
  mixpanelTitle,
  hasLink,
  sailthruSectionId,
  hasSailthruCookie,
  fallbackData = []
}: StandardRibbonProps) => {
  const hasMounted = useDidMount();

  const {
    data: personalizedData,
    refetch: fetchPersonalizedData,
    isFetching: isFetchingPersonalizedData
  } = usePersonalizedContent(sailthruSectionId);

  useEffect(() => {
    if (hasSailthruCookie && hasMounted) {
      fetchPersonalizedData();
    }
  }, [fetchPersonalizedData, hasSailthruCookie, hasMounted]);

  const talkData = useMemo(() => {
    if (isSailthru) {
      if (!isEmpty(personalizedData)) {
        return orderBy(personalizedData, ['video.publishedAt'], ['desc'])
          .slice(0, 4)
          .map(({ video }: SailthruContentProps) => video);
      }

      if (!isEmpty(data)) {
        return orderBy(data, ['video.publishedAt'], ['desc']).map(
          ({ video }: SailthruContentProps) => video
        );
      }

      return fallbackData as Partial<Video>[];
    }

    if (isProgrammer) {
      return sortBy(data as Slot[], 'order')
        .slice(0, 4)
        .map(({ video }) => video);
    }

    return data as Partial<Video>[];
  }, [data, personalizedData, isProgrammer, isSailthru, fallbackData]);

  const isMobileWidth = useIsBreakpointWidth({
    size: 'sm',
    breakPointType: 'tui'
  });

  const isTabletWidth = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'tui'
  });

  const ribbonClasses = cx({
    'grid-row-4 mt-4 grid gap-12': isMobileWidth,
    'divide-x-8 -ml-5 mt-4 grid grid-cols-4 gap-5 divide-x-thin':
      !isMobileWidth && !isTabletWidth
  });

  const ribbonCardsClasses = cx('flex w-full', {
    'pl-5': !isMobileWidth && !isTabletWidth,
    'pl-5 last:border-l-thin': isTabletWidth && !isMobileWidth
  });

  if (isFetchingPersonalizedData && isSailthru) {
    return (
      <StandardCardSkeleton
        ribbonClasses={ribbonClasses}
        isMobileWidth={isMobileWidth}
        ribbonCardsClasses={ribbonCardsClasses}
      />
    );
  }

  if (isEmpty(talkData)) {
    return <></>;
  }

  const primaryLink = (
    <Link isZenithLink href={linkUrl}>
      <Text
        variant="link2"
        UNSAFE_className="underline"
        weight="font-semibold"
        useNewTextStyles
      >
        {linkText}
      </Text>
    </Link>
  );

  return (
    <ContentContainer className="relative pb-12 pt-0">
      <div className="flex items-center justify-between border-t-thin border-black pt-3">
        <Text variant="label2" weight="font-bold" useNewTextStyles>
          {title}
        </Text>
        {!isMobileWidth && hasLink && primaryLink}
      </div>
      {(isMobileWidth || (!isMobileWidth && !isTabletWidth)) && (
        <div className={ribbonClasses}>
          {talkData.map((data: Video, index: number) => {
            return (
              <div
                className={ribbonCardsClasses}
                key={`data-isMobileWidth-${index}`}
              >
                <StandardCard
                  data={data}
                  index={index + 1}
                  mixpanelTitle={mixpanelTitle}
                />
              </div>
            );
          })}
        </div>
      )}
      {isTabletWidth && !isMobileWidth && (
        <div className="mt-4 grid grid-rows-2 gap-12">
          <div className="-ml-5 grid grid-cols-2 gap-5">
            {talkData.slice(0, 2).map((data: Video, index: number) => {
              return (
                <div
                  className={ribbonCardsClasses}
                  key={`data-isTabletWidth-${index}`}
                >
                  <StandardCard
                    data={data}
                    index={index + 1}
                    mixpanelTitle={mixpanelTitle}
                  />
                </div>
              );
            })}
          </div>

          <div className="-ml-5 grid grid-cols-2 gap-5">
            {talkData.slice(2, 4).map((data: Video, index: number) => {
              return (
                <div className={`${ribbonCardsClasses}`} key={`data-${index}`}>
                  <StandardCard
                    data={data}
                    index={index + 1}
                    mixpanelTitle={mixpanelTitle}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}

      {isMobileWidth && hasLink && (
        <div className="my-6 flex w-full justify-end">{primaryLink}</div>
      )}
    </ContentContainer>
  );
};

export default StandardRibbon;
