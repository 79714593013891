import SkeletonItem from '../../SkeletonItem';
import ContentContainer from 'components/content-container';

export const NumberedCardRibbon = ({ headerClasses, isMobileWidth }) => (
  <ContentContainer className="relative py-6">
    <div className={headerClasses} />

    <div className="grid sm:grid-cols-1 sm:gap-0 sm:divide-y-thin md:grid-cols-2 md:gap-4">
      {[...Array(6)].map((_item, index) => {
        return (
          <div
            key={index}
            className="flex h-full items-center sm:bg-white sm:py-6 md:bg-[#F6F6F6] md:py-8"
          >
            <div className="px-10">
              <SkeletonItem className="h-14 w-[40px]" />
            </div>

            <div className="grid grid-cols-1 gap-1">
              <SkeletonItem className="h-4 w-[220px]" />
              <SkeletonItem className="h-4 w-[220px]" />
              <SkeletonItem className="h-4 w-[220px]" />
            </div>
          </div>
        );
      })}
    </div>
    {isMobileWidth && <div className="my-6 flex w-full justify-end"></div>}
  </ContentContainer>
);

export default NumberedCardRibbon;
