import { Configure } from 'react-instantsearch';

export function CustomConfigure(props): React.ReactNode {
  return (
    <Configure
      {...props}
      hitsPerPage={24}
      distinct={1}
      attributeForDistinct="objectID"
    />
  );
}
