import { Video } from 'api';
import cx from 'classnames';
import { Text } from 'components/@tedui';
import Image from 'components/image';
import { Link } from 'components/router';
import { formatDistanceToNowStrict } from 'date-fns';
import { useAuthenticated } from 'lib/auth';

import AnimatedIconButton from 'components/AnimatedIconButton';
import { ViewCount } from 'lib/business';
import getImageFromSet from 'lib/get-image-from-set';
import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';
import { useOptimizedImage } from 'lib/hooks/useOptimizedImage';
import { useIntl } from 'react-intl';
import type { LINK_TYPE } from '../../HeroSlider';

interface HorizontalCardItemProps {
  data: Partial<Video>;
  index: number;
  trackClick: (linkType: LINK_TYPE, itemNumber: number) => void;
  mixpanelTitle: string;
}

const HorizontalCardItem = ({
  data,
  index,
  trackClick,
  mixpanelTitle
}: HorizontalCardItemProps) => {
  const { formatNumber } = useIntl();
  const loggedIn = useAuthenticated();

  const isMobileWidth = useIsBreakpointWidth({
    size: 'sm',
    breakPointType: 'tui'
  });
  const featuredImage = getImageFromSet(data?.primaryImageSet, '16x9');
  const { src, blurredSrc } = useOptimizedImage({
    imageUrl: featuredImage,
    width: 1200,
    height: 675
  });

  const cardClasses = cx('w-full', {
    'grid grid-cols-2 gap-10 pt-4': !isMobileWidth,
    'flex flex-col-reverse': isMobileWidth
  });

  const topicName = data.topics?.nodes[0]?.name;

  const linkUrl = `/talks/${data.slug}`;
  const titleLink = `${linkUrl}`;
  const videoLink = `${linkUrl}`;
  const topicLink = `/talks?sort=relevance&topics%5B0%5D=${topicName}`;

  return (
    <div className={cardClasses}>
      <div>
        <div className="mt-3 flex items-center justify-between lg-tui:mt-0">
          <Link
            href={topicLink}
            isZenithLink
            onClick={() => trackClick('topic', index)}
          >
            <div className="mt-3 lg-tui:mt-0">
              <Text
                variant="label1"
                tag="p"
                weight="font-semibold"
                color={{ override: '#EB0028' }}
              >
                {topicName}
              </Text>
            </div>
          </Link>
          {loggedIn && (
            <AnimatedIconButton
              talk={data}
              mixpanelTitle={mixpanelTitle}
              itemNumber={String(index)}
            />
          )}
        </div>

        <Link
          href={titleLink}
          isZenithLink
          onClick={() => trackClick('title', index)}
        >
          <Text
            variant="header3"
            useNewTextStyles
            tag="p"
            weight="font-semibold"
            UNSAFE_className="mt-2"
          >
            {data?.title}
          </Text>

          <div className="mt-2">
            <Text tag="p" variant="caption1" useNewTextStyles>
              <>{data?.presenterDisplayName} · </>
              <ViewCount count={data!.viewedCount}>
                {formatNumber(data!.viewedCount, {
                  notation: 'compact',
                  compactDisplay: 'short'
                })}{' '}
                plays ·{' '}
              </ViewCount>
              {formatDistanceToNowStrict(new Date(data!.publishedAt))} ago
            </Text>
          </div>
        </Link>
      </div>
      {src && (
        <Link
          href={videoLink}
          isZenithLink
          onClick={() => trackClick('image', index)}
        >
          <div className="aspect-h-9 aspect-w-16">
            <Image
              alt={data.slug}
              src={src}
              placeholder="blur"
              blurDataURL={blurredSrc}
              fill
              className="object-cover"
            />
          </div>
        </Link>
      )}
    </div>
  );
};

export default HorizontalCardItem;
