import cx from 'classnames';
import { Text } from 'components/@tedui';
import AnimatedIconButton from 'components/AnimatedIconButton';
import { Link } from 'components/router';
import { formatDistanceToNowStrict } from 'date-fns';
import mixpanel from 'lib/analytics/mixpanel';
import { useAuthenticated } from 'lib/auth';
import { ViewCount } from 'lib/business';
import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';
import { useIntl } from 'react-intl';
import type { LINK_TYPE } from '../../HeroSlider';

const NumberedCard = ({ talk, talkNumber, mixpanelTitle }) => {
  const { formatNumber } = useIntl();
  const loggedIn = useAuthenticated();

  const topic = talk?.topics?.nodes[0]?.name;
  const topicLink = `/talks?sort=relevance&topics%5B0%5D=${topic}`;

  const cardLink = `/talks/${talk.slug}`;

  const trackClick = (linkType: LINK_TYPE, itemNumber: number) => {
    mixpanel.track('homepage_click', {
      current_url: window.location.href,
      title: mixpanelTitle,
      item_number: String(itemNumber),
      link_type: linkType,
      value: ''
    });
  };

  const isMobileWidth = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'tui'
  });

  const numberClasses = cx({
    'w-18 text-center': isMobileWidth,
    'px-10': !isMobileWidth
  });

  return (
    <div>
      <div className="flex h-full items-center rounded-sm sm:py-5 md:py-5 xs-tui:bg-white md-tui:bg-[#F6F6F6]">
        <Link href={cardLink} isZenithLink>
          <Text
            tag="p"
            variant="header2"
            UNSAFE_className={numberClasses}
            useNewTextStyles
          >
            {talkNumber}
          </Text>
        </Link>
        <div className="grid w-full grid-cols-1 gap-1 pr-0 sm-tui:pr-8 lg-tui:pr-20">
          <div className="flex items-center justify-between">
            <Link
              href={topicLink}
              isZenithLink
              onClick={() => trackClick('topic', talkNumber)}
            >
              <Text
                tag="p"
                weight="font-semibold"
                variant="label1"
                color={{ override: '#EB0028' }}
                useNewTextStyles
              >
                {topic}
              </Text>
            </Link>
            {loggedIn && (
              <AnimatedIconButton
                talk={talk}
                mixpanelTitle={mixpanelTitle}
                itemNumber={talkNumber}
              />
            )}
          </div>

          <Link
            href={cardLink}
            isZenithLink
            onClick={() => trackClick('title', talkNumber)}
          >
            <Text
              tag="p"
              weight="font-semibold"
              variant="subheader1"
              useNewTextStyles
            >
              {talk.title}
            </Text>

            <Text
              tag="p"
              variant="caption1"
              useNewTextStyles
              UNSAFE_className="pt-2"
            >
              {talk?.presenterDisplayName} ·{' '}
              <ViewCount count={talk!.viewedCount}>
                {formatNumber(talk!.viewedCount, {
                  notation: 'compact',
                  compactDisplay: 'short'
                })}{' '}
                plays ·{' '}
              </ViewCount>
              {formatDistanceToNowStrict(new Date(talk!.publishedAt))} ago
            </Text>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NumberedCard;
