import { CardState } from './NewsletterRibbonForm.props';

export const initialCardState: Record<string, CardState> = {
  TEDRecommends: {
    period: 'weekly',
    title: 'TED Recommends',
    subtitle: 'TED, tailored for you',
    newsletter_id: 'TEDRecommends',
    img: 'https://pl.tedcdn.com/newsletter_assets/newsletter_ted_rec.png',
    isSubscribed: false,
    isSelected: false
  },
  TEDTalksDaily: {
    period: 'daily',
    title: 'TED Talks Daily',
    subtitle: 'The latest from TED',
    newsletter_id: 'TEDTalksDaily',
    img: 'https://pl.tedcdn.com/newsletter_assets/newsletter_ted_tdd.png',
    isSubscribed: false,
    isSelected: false
  },
  TEDEd: {
    period: 'weekly',
    title: 'TED-Ed',
    subtitle: 'Learn something new',
    newsletter_id: 'TEDEd',
    img: 'https://pl.tedcdn.com/newsletter_assets/newsletter_ted_ed.png',
    isSubscribed: false,
    isSelected: false
  },
  TEDTech: {
    period: 'biweekly',
    title: 'TED Tech',
    subtitle: 'Tech news, talks + more',
    newsletter_id: 'TEDTech',
    img: 'https://pi.tedcdn.com/r/ted-conferences-general-web-assets.s3.amazonaws.com/newsletter_assets/newsletter_ted_talks_tech.png',
    isSubscribed: false,
    isSelected: false
  }
};
