import { SliceZone } from '@prismicio/react';
import { Ribbon, Video } from 'api';
import { Text } from 'components/@tedui';
import { isNil, map, slice } from 'lodash-es';
import { memo, useCallback } from 'react';

import NewsletterRibbonForm from 'components/NewsletterRibbon/NewsletterRibbonForm';
import { HomePageAds, LazyLoadAd } from 'components/ads';
import ContentContainer from 'components/content-container';
import Divider from 'components/divider';
import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';
import {
  SailthruDataContentType,
  SailthruSections,
  sections
} from 'lib/sailthru';
import Head from './homepage-head';

import { sortBy } from 'f';
import mixpanel from 'lib/analytics/mixpanel';
import { useFeatureFlags } from 'lib/vwo';
import { parseCookies } from 'nookies';
import { HomepageDocument } from '../../../../prismicio-types';
import { components } from '../../PRISMIC/slices';
import { EditorialGrid } from './EditorialGrid';
import { HeroSlider } from './HeroSlider';
import HomepageFeaturedSpotlight from './HomepageFeaturedSpotlight';
import HorizontalCardRibbon from './HorizontalCardRibbon';
import ImageHeroWithRibbon from './ImageHeroWithRibbon';
import NumberedCardRibbon from './NumberedCardRibbon';
import StandardRibbon from './StandardRibbon';
import TopicsLaunchPadHomepage from './TopicsLaunchPadHomepage';
import ABCTESTSDATA from './data/homepage-abc-tests.json';
import { sailthruDataSections } from './data/sailthruDataSections';

type HomepageProps = {
  programmerRibbons: Array<Ribbon>;
  prismicPage: HomepageDocument;
  sailthruDataContent: SailthruDataContentType;
  sailthruFallback: SailthruDataContentType;
};

function Homepage({
  programmerRibbons,
  prismicPage,
  sailthruDataContent,
  sailthruFallback
}: HomepageProps): React.ReactNode {
  const isMobileWidth = useIsBreakpointWidth({
    size: 'sm',
    breakPointType: 'tui'
  });

  const sailthruCookie = parseCookies().sailthru_hid;
  const hasSailthruCookie = !isNil(sailthruCookie);

  const isEditoriallyControlledRibbonEnabled = useFeatureFlags(
    state => state.isEditoriallyControlledRibbonEnabled
  );

  const { videos: featuredSpotlightData } = ABCTESTSDATA.data;
  const editorialSection1 = programmerRibbons.slice(0, 1);
  const editoriallyRibbon = programmerRibbons.slice(1, 2);

  const featuredSection = [
    ...sortBy(video => video?.order, editorialSection1[0]?.slots?.nodes)
      .map(node => node?.video)
      .slice(0, 5)
  ];

  const sailthruRecommendationsData =
    sailthruDataContent[SailthruSections.Recommendations];

  const heroSliderSlides = featuredSpotlightData.nodes.slice(0, 4);

  const getPersonalizedEditorialGridData = useCallback(() => {
    return {
      primary: sailthruRecommendationsData[0].video,
      secondary: sailthruRecommendationsData[1].video,
      tertiary: map(sailthruRecommendationsData.slice(2, 6), 'video')
    };
  }, [sailthruRecommendationsData]);

  const newestTalks = slice(
    sailthruDataContent[SailthruSections.RecommendationsNewest],
    0,
    4
  );
  const popularTalks = slice(
    sailthruDataContent[SailthruSections.RecommendationsPopular],
    0,
    4
  );
  const trendingTalks = slice(
    sailthruDataContent[SailthruSections.RecommendationsTrending],
    0,
    6
  );
  const countdownTalks = slice(
    sailthruDataContent[SailthruSections.RecommendationsCountdown],
    0,
    3
  );
  const podcastTalks = slice(
    sailthruDataContent[SailthruSections.RecommendationsPodcast],
    0,
    4
  );

  const recommendationTalks = {
    ...getPersonalizedEditorialGridData()
  };

  const fallbackNewestTalks =
    sailthruFallback[SailthruSections.RecommendationsNewest];
  const fallbackPopularTalks =
    sailthruFallback[SailthruSections.RecommendationsPopular];
  const fallbackTrendingTalks =
    sailthruFallback[SailthruSections.RecommendationsTrending];
  const fallbackCountdownTalks =
    sailthruFallback[SailthruSections.RecommendationsCountdown];
  const fallbackPodcastTalks =
    sailthruFallback[SailthruSections.RecommendationsPodcast];

  return (
    <>
      <Head />
      <div id="homepage" className="relative w-full bg-white text-black">
        <div
          id="cloned-standard-hero"
          data-testid="cloned-standard-hero-slider"
          className="lg-tui:invisible lg-tui:h-0"
        >
          <HeroSlider
            id="cloned-standard-hero-slider"
            slides={featuredSection}
          />
          <section id="launchpad-homepage">
            <TopicsLaunchPadHomepage mixpanelTitle="topic-launchpad" />
          </section>
        </div>

        <div
          id="standard-hero"
          data-testid="standard-hero-slider"
          className="invisible h-0"
        >
          <HeroSlider id="standard-hero-slider" slides={heroSliderSlides} />
        </div>

        <section
          data-testid="section__editorial-one"
          id="editorial-section"
          className="invisible h-0 pb-12 lg-tui:visible lg-tui:h-min"
        >
          <HomepageFeaturedSpotlight
            spotLightData={featuredSection[0] as Video}
            ribbonData={featuredSection.slice(1, 5) as Video[]}
          />

          <section id="launchpad-homepage" className="bg-pureBlack">
            <TopicsLaunchPadHomepage mixpanelTitle="topic-launchpad" />
          </section>
        </section>

        <>
          <Divider />
          <section
            data-testid="section__homepage-ad-abtf"
            className="flex flex-row items-center justify-center pb-12"
            onClick={() => {
              mixpanel.track('homepage_click', {
                current_url: window.location.href,
                title: 'ad-abtf',
                item_number: '',
                link_type: 'image',
                value: ''
              });
            }}
          >
            <LazyLoadAd>
              <HomePageAds.HomeAdInlineATF />
            </LazyLoadAd>
          </section>
        </>

        <div
          id="personalized-grid-cookie"
          data-testid="personalized-grid-cookie"
        >
          <EditorialGrid
            title="RECOMMENDED FOR YOU"
            sailthruSectionId={sections[SailthruSections.Recommendations]}
            slots={recommendationTalks}
            hasSailthruCookie={hasSailthruCookie}
            mixpanelTitle="recommended-for-you"
          />
        </div>

        <section
          id="newest_standard_ribbon"
          data-testid="section__newest_standard_ribbon"
        >
          <StandardRibbon
            hasLink
            isSailthru
            hasSailthruCookie={hasSailthruCookie}
            data={newestTalks}
            sailthruSectionId={sections[SailthruSections.RecommendationsNewest]}
            title="Newest from TED"
            linkText="Browse all Newest"
            mixpanelTitle="newest-ribbon"
            linkUrl="/talks?sort=newest"
            fallbackData={fallbackNewestTalks}
          />
        </section>

        <section
          id="popular_on_ted_standard_ribbon"
          data-testid="section__popular_on_ted_standard_ribbon"
        >
          <StandardRibbon
            hasLink
            isSailthru
            hasSailthruCookie={hasSailthruCookie}
            data={popularTalks}
            sailthruSectionId={
              sections[SailthruSections.RecommendationsPopular]
            }
            title="POPULAR ON TED"
            linkText="Browse all Popular"
            mixpanelTitle="popular-ribbon"
            linkUrl="/talks?sort=popular"
            fallbackData={fallbackPopularTalks}
          />
        </section>
        <div className="mt-12 md-tui:mt-0 md-tui:hidden">
          <Divider />
        </div>
        <section
          data-testid="section__prismic"
          className="flex flex-row items-center justify-center px-0 pb-12 sm-tui:px-5"
        >
          <SliceZone
            slices={prismicPage.data.slices2}
            components={components}
            context={{ mixpanelTitle: 'marketing-spotlight' }}
          />
        </section>

        <section>
          <ContentContainer className="relative" noPadding={isMobileWidth}>
            <div className="flex items-center justify-between border-t-thin border-black pb-4 pt-3 xs-tui:pl-5 sm-tui:pl-0">
              <Text variant="label2" useNewTextStyles isBold>
                Trending ideas
              </Text>
            </div>
          </ContentContainer>
          {sailthruDataSections.map(section => (
            <section
              key={`image-hero-with-ribbon-${section.contentType}`}
              id={`image-hero-with-ribbon-${section.contentType}`}
              data-testid={`image-hero-with-ribbon-${section.contentType}`}
            >
              <ImageHeroWithRibbon
                fallbackData={sailthruFallback[section.contentType]}
                talks={sailthruDataContent[section.contentType]}
                title={section.title}
                textHeader={section.headerText}
                headerLink={section.headerLink}
                headerLinkText={section.headerLinkText}
                sailthruSectionId={sections[section.contentType]}
                hasSailthruCookie={hasSailthruCookie}
                mixpanelTitle={section.mixpanelTitle ?? ''}
              />
            </section>
          ))}
          <SliceZone
            slices={prismicPage.data.slices4}
            components={components}
            context={{ mixpanelTitle: 'trending-partnerships' }}
          />
          <section data-testid="section__trending-talks">
            <NumberedCardRibbon
              talks={trendingTalks}
              title="trending tedx ideas"
              isSailthru
              sailthruSectionId={
                sections[SailthruSections.RecommendationsTrending]
              }
              hasSailthruCookie={hasSailthruCookie}
              mixpanelTitle={'more-trending'}
              fallbackData={fallbackTrendingTalks}
            />
          </section>
        </section>

        <section
          data-testid="section__about-ted"
          className="flex flex-row items-center justify-center px-5 pb-12"
        >
          <SliceZone
            slices={prismicPage.data.slices3}
            components={components}
            context={{ mixpanelTitle: 'about-ted' }}
          />
        </section>

        <section
          id="horizontal-card-ribbon"
          data-testid="section__horizontal-card-ribbon"
        >
          <HorizontalCardRibbon
            data={countdownTalks}
            title="Countdown: Take action"
            linkText="Browse all Sustainability"
            linkUrl="/talks?sort=relevance&topics%5B0%5D=sustainability"
            isSailthru
            sailthruSectionId={
              sections[SailthruSections.RecommendationsCountdown]
            }
            hasSailthruCookie={hasSailthruCookie}
            mixpanelTitle="countdown"
            fallbackData={fallbackCountdownTalks}
          />
        </section>

        <section
          id="podcast_standard_ribbon"
          data-testid="section__podcast_standard_ribbon"
        >
          <StandardRibbon
            isSailthru
            hasLink
            data={podcastTalks}
            title="Popular podcast episodes"
            linkText="Browse all Podcasts"
            linkUrl="/talks?q=podcast-audio-only&sort=newest"
            mixpanelTitle="podcast-ribbon"
            fallbackData={fallbackPodcastTalks}
          />
        </section>

        {isEditoriallyControlledRibbonEnabled && (
          <section
            id="editorially_ted_standard_ribbon"
            data-testid="section__editorially_ted_standard_ribbon"
          >
            <StandardRibbon
              isProgrammer
              data={editoriallyRibbon[0]?.slots?.nodes ?? []}
              title={editoriallyRibbon[0]?.name ?? ''}
              mixpanelTitle="editorial-ribbon"
            />
          </section>
        )}
        <NewsletterRibbonForm />
      </div>
    </>
  );
}

export default memo(Homepage);
