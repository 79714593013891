import Client from '@searchkit/instantsearch-client';
import singletonRouter from 'next/router';
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs';

// Ensure mainIndex is properly typed
const mainIndex = process.env.NEXT_PUBLIC_ELASTIC_SEARCH_TALKS_INDEX_NAME || '';

export interface RouteState {
  q?: string;
  sort?: string;
  topics?: string | string[];
  language?: string;
  duration?: string;
}

// Define the shape of our UI state
export type CustomUiState = {
  query?: string;
  sortBy?: string;
  refinementList?: {
    tags: string[];
  };
  menu?: {
    subtitle_languages: string;
  };
  numericMenu?: {
    duration: string;
  };
  [key: string]: unknown;
};

const searchClient = Client({
  url: process.env.NEXT_PUBLIC_SEARCH_PROXY_URL as string
});

const routing = {
  router: createInstantSearchRouterNext({
    singletonRouter,
    routerOptions: {
      cleanUrlOnDispose: false
    }
  }),
  stateMapping: {
    stateToRoute(uiState: Record<string, CustomUiState>): RouteState {
      const indexUiState = uiState[mainIndex] || {};
      const duration = indexUiState.numericMenu?.duration;

      if (!duration) {
        return {
          q: indexUiState.query,
          sort: indexUiState.sortBy,
          topics: indexUiState.refinementList?.tags,
          language: indexUiState.menu?.subtitle_languages
        };
      }

      const [start, end] = duration.split(':').map(d => Number(d) / 60);
      const durationString = end ? `${start}-${end}` : `${start}`;

      return {
        q: indexUiState.query,
        sort: indexUiState.sortBy,
        topics: indexUiState.refinementList?.tags,
        language: indexUiState.menu?.subtitle_languages,
        duration: durationString
      };
    },
    routeToState(routeState: RouteState): Record<string, CustomUiState> {
      const { q, sort, topics, language, duration } = routeState;

      // Handle duration conversion
      let durationValue: string | undefined;
      if (duration) {
        const [start, end] = duration.split('-').map(d => Number(d) * 60);
        durationValue = end ? `${start}:${end}` : `${start}:`;
      }

      // Determine if sort should default to "relevance"
      const shouldDefaultSort = (q || topics || language || duration) && !sort;
      const effectiveSort = shouldDefaultSort ? 'relevance' : sort;

      // Ensure topics is always an array
      const topicsArray = topics
        ? Array.isArray(topics)
          ? topics
          : [topics]
        : [];

      const uiState: CustomUiState = {
        ...(q && { query: q }),
        ...(effectiveSort && { sortBy: effectiveSort }),
        ...(topicsArray.length > 0 && {
          refinementList: { tags: topicsArray }
        }),
        ...(language && { menu: { subtitle_languages: language } }),
        ...(durationValue && {
          numericMenu: {
            duration: durationValue
          }
        })
      };

      return { [mainIndex]: uiState };
    }
  }
};

export { mainIndex, routing, searchClient };
