import { useEffect, useMemo } from 'react';

import { Text } from 'components/@tedui';
import ContentContainer from 'components/content-container';

import { isBrowser } from 'react-device-detect';

import usePersonalizedContent from 'lib/hooks/useSailthru/usePersonalizedContent';
import { isEmpty, map } from 'lodash-es';
import type { EditorialGridProps } from './EditorialGrid.props';
import { useDidMount } from 'lib/hooks/useDidMount';
import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';
import EditorialOneColumn from './EditorialOneColumn';
import EditorialThreeColumns from './EditorialThreeColumns';
import EditorialTwoColumns from './EditorialTwoColumns';
import EditorialTwoColumnsWithImage from './EditorialTwoColumnsWithImage';
import EditorialSkeleton from './GridSkeleton/EditorialGridSkeleton';

function EditorialContainer({
  children,
  title
}: {
  children: React.ReactNode;
  title: string;
}): React.ReactNode {
  return (
    <ContentContainer className="relative pb-12">
      <div className="border-t-thin border-black pb-8 pt-3 sm-tui:pb-4">
        <Text variant="label2" weight="font-bold" useNewTextStyles>
          {title}
        </Text>
      </div>

      {children}
    </ContentContainer>
  );
}

export function EditorialGrid({
  slots,
  title = 'Featured',
  sailthruSectionId,
  hasSailthruCookie,
  mixpanelTitle
}: EditorialGridProps): React.ReactNode {
  const isMobileWidth = useIsBreakpointWidth({
    size: 'sm',
    breakPointType: 'tui'
  });

  const hasMounted = useDidMount();

  const {
    data: personalizedData,
    refetch: fetchPersonalizedData,
    isFetching: isFetchingPersonalizedData
  } = usePersonalizedContent(sailthruSectionId);

  useEffect(() => {
    if (hasSailthruCookie && hasMounted && isBrowser) {
      fetchPersonalizedData();
    }
  }, [fetchPersonalizedData, isMobileWidth, hasSailthruCookie, hasMounted]);

  const editorialData = useMemo(() => {
    if (!isEmpty(personalizedData)) {
      return {
        primary: personalizedData[0].video,
        secondary: personalizedData[1].video,
        tertiary: map(personalizedData.slice(2, 6), 'video')
      };
    }

    return slots;
  }, [slots, personalizedData]);

  const { primary, secondary, tertiary } = editorialData;

  if (isFetchingPersonalizedData) {
    return (
      <EditorialContainer title={title}>
        <EditorialSkeleton />
      </EditorialContainer>
    );
  }

  if (isEmpty(primary) || isEmpty(secondary) || isEmpty(tertiary)) {
    return <></>;
  }

  const allContent = [primary, secondary, ...tertiary];

  return (
    <EditorialContainer title={title}>
      <div>
        {!isMobileWidth && (
          <>
            <EditorialThreeColumns
              primary={primary}
              secondary={secondary}
              tertiary={tertiary}
              mixpanelTitle={mixpanelTitle}
            />

            <EditorialTwoColumnsWithImage
              primary={primary}
              secondary={secondary}
              tertiary={tertiary}
              mixpanelTitle={mixpanelTitle}
            />

            <EditorialTwoColumns
              talks={allContent}
              mixpanelTitle={mixpanelTitle}
            />
          </>
        )}

        {/* 639 - 320 */}
        {isMobileWidth && (
          <EditorialOneColumn
            talks={allContent}
            mixpanelTitle={mixpanelTitle}
          />
        )}
      </div>
    </EditorialContainer>
  );
}
