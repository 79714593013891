import { crush } from '@tedconf/js-crushinator-helpers';
import cx from 'classnames';
import { formatDistanceToNowStrict } from 'date-fns';
import { isEmpty, toLower } from 'lodash-es';
import { useEffect, useMemo, type JSX } from 'react';
import { useIntl } from 'react-intl';

import { Badge, Text } from 'components/@tedui';
import AnimatedIconButton from 'components/AnimatedIconButton';
import ContentContainer from 'components/content-container';
import Image from 'components/image';
import { Link } from 'components/router';
import { getBadgeText } from 'components/talk-card/utils';

import mixpanel from 'lib/analytics/mixpanel';
import { useAuthenticated } from 'lib/auth';
import { ViewCount } from 'lib/business';
import getImageFromSet from 'lib/get-image-from-set';
import { useDidMount } from 'lib/hooks/useDidMount';
import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';
import usePersonalizedContent from 'lib/hooks/useSailthru/usePersonalizedContent';

import type { LINK_TYPE } from '../HeroSlider';
import ImageHeroWithRibbonItem from './ImageHeroWithRibbonItem';
import Skeleton from './Skeleton/ImageHereRibbonSkeleton';
import { Video } from 'api';
import { Recommendation } from 'lib/sailthru';

const IMAGE_WIDTH = 1600;
type ImageHeroWithRibbonProps = {
  talks: { video: Partial<Video> }[];
  title: string;
  textHeader: string;
  headerLink: string;
  headerLinkText: string;
  sailthruSectionId?: string | null;
  hasSailthruCookie?: boolean;
  mixpanelTitle: string;
  fallbackData?: Recommendation[];
};

const ImageHeroWithRibbon = ({
  talks,
  title,
  textHeader,
  headerLink,
  headerLinkText,
  sailthruSectionId = null,
  hasSailthruCookie = false,
  mixpanelTitle,
  fallbackData = []
}: ImageHeroWithRibbonProps): JSX.Element => {
  const hasContent = !isEmpty(talks);
  const { formatNumber } = useIntl();
  const loggedIn = useAuthenticated();

  const isMobileWidth = useIsBreakpointWidth({
    size: 'sm',
    breakPointType: 'tui'
  });

  const hasMounted = useDidMount();

  const {
    data: personalizedData,
    refetch: fetchPersonalizedData,
    isFetching: isFetchingPersonalizedData
  } = usePersonalizedContent(sailthruSectionId);

  useEffect(() => {
    if (hasSailthruCookie && hasMounted && sailthruSectionId) {
      fetchPersonalizedData();
    }
  }, [fetchPersonalizedData, hasSailthruCookie, hasMounted]);

  const content = useMemo(() => {
    if (!isEmpty(personalizedData)) {
      return personalizedData.slice(0, 4);
    }

    if (hasContent) {
      return talks;
    }

    return fallbackData;
  }, [personalizedData, talks, fallbackData, hasContent]);

  const headerClasses = cx(
    'border-t-thin border-[rgba(18,18,18,0.16)] sm-tui:pb-5 sm-tui:pt-3 md-tui:pb-5 md-tui:pt-3 lg-tui:pb-6 lg-tui:pt-4 xl-tui:pb-8 xl-tui:pt-5',
    {
      'grid grid-cols-2 gap-10': !isMobileWidth,
      'flex flex-col gap-0 px-5': isMobileWidth
    }
  );

  const ribbonClasses = cx({
    'grid-row-3 mt-3 grid gap-6 divide-y-thin px-5': isMobileWidth,
    '-ml-5 mt-5 grid grid-cols-4 gap-5 divide-x-thin': !isMobileWidth
  });

  const ribbonCardsClasses = cx('w-full', {
    'pl-5': !isMobileWidth,
    'grid grid-cols-2 gap-6 pt-6': isMobileWidth
  });
  const mainCardRibbon = cx({
    'col-span-2 pl-5': !isMobileWidth
  });
  const mainCardRibbonDescription = cx('mt-2', {
    flex: isMobileWidth
  });

  const trackClick = (linkType: LINK_TYPE, itemNumber: number) => {
    if (mixpanelTitle)
      mixpanel.track('homepage_click', {
        current_url: window.location.href,
        title: mixpanelTitle,
        item_number: String(itemNumber),
        link_type: linkType,
        value: ''
      });
  };

  if (isFetchingPersonalizedData) {
    return (
      <Skeleton
        isMobileWidth={isMobileWidth}
        classes={{
          headerClasses,
          ribbonClasses,
          ribbonCardsClasses,
          mainCardRibbon,
          mainCardRibbonDescription
        }}
      />
    );
  }

  if (isEmpty(content)) {
    return <></>;
  }

  const heroTalk = content[0].video;
  const heroTalkDuration = Math.floor(heroTalk.duration / 60);
  const ribbonTalks = content.slice(1, 3);

  const badgeText = heroTalk && getBadgeText(heroTalk.type?.name || '');

  const topicName = heroTalk?.topics?.nodes[0]?.name;
  const topicLink = `/talks?sort=relevance&${
    toLower(title) === 'ted-ed'
      ? `topics%5B0%5D=ted-ed&topics%5B1%5D=${topicName}`
      : `topics%5B0%5D=${topicName}`
  }`;

  const featuredImage = getImageFromSet(heroTalk?.primaryImageSet, '16x9');

  const primaryLink = (
    <Link isZenithLink href={headerLink}>
      <Text
        variant={'link2'}
        UNSAFE_className="underline font-semibold sm-tui:mt-0 sm-tui:mt-3"
        useNewTextStyles
        tag="p"
      >
        {headerLinkText}
      </Text>
    </Link>
  );

  const heroTalkLink = `/talks/${heroTalk?.slug}`;

  return (
    <ContentContainer className="relative pb-12" noPadding={isMobileWidth}>
      <div id="slide-header" className={headerClasses}>
        <div className="sm:pb-4 sm:pt-6 md:py-0">
          <Text
            tag="p"
            variant="display"
            useNewTextStyles
            UNSAFE_className="font-semibold"
          >
            {title}
          </Text>
        </div>
        <div className="w-full">
          <Text
            tag="p"
            UNSAFE_className="sm:text-textTertiary-onLight md:text-textPrimary-onLight pb-2 xs-tui:pb-6 sm-tui:pb-0 md-tui:pb-0"
            variant="subheader2"
            useNewTextStyles
          >
            {textHeader}
          </Text>
          {!isMobileWidth && primaryLink}
        </div>
      </div>
      {isMobileWidth && <div className="mb-6" />}
      <Link
        href={`${heroTalkLink}`}
        isZenithLink
        onClick={() => trackClick('image', 1)}
      >
        <div className="relative">
          <div className="aspect-h-9 aspect-w-16">
            <Image
              fill
              className="object-cover"
              src={crush(featuredImage, { width: IMAGE_WIDTH, quality: 80 })}
              alt={heroTalk?.slug}
              sizes="@media (min-width: 768px) 90vw, 100vw"
            />
          </div>

          {badgeText && (
            <div className={'absolute bottom-[0.6rem] left-2'}>
              <Badge
                text={badgeText.large}
                testId="badge--bottomLeft"
                isDarkBackground={false}
                backgroundColor="white"
                UNSAFE_className="!rounded-xs"
              />
            </div>
          )}

          <Text
            variant="unset"
            UNSAFE_className="md:invisible sm:visible text-tui-xs text-white absolute bottom-2 right-2 font-bold bg-textSecondary-onLight px-1 rounded-xs uppercase leading-[20.7px] -tracking-[0.72px]"
            tag="p"
          >
            {heroTalkDuration} min
          </Text>
        </div>
      </Link>

      <div className={ribbonClasses}>
        <div className={mainCardRibbon}>
          <div className="flex items-center justify-between">
            <Link
              href={topicLink}
              isZenithLink
              onClick={() => trackClick('topic', 1)}
            >
              <Text
                color={{ override: '#EB0028' }}
                variant="label1"
                useNewTextStyles
                weight="font-semibold"
                tag="p"
              >
                {topicName}
              </Text>
            </Link>
            {loggedIn && (
              <AnimatedIconButton
                talk={heroTalk}
                mixpanelTitle={mixpanelTitle as string}
              />
            )}
          </div>

          <Link
            href={`${heroTalkLink}`}
            isZenithLink
            onClick={() => trackClick('title', 1)}
          >
            <div className="my-2">
              <Text
                tag="p"
                variant="header3"
                useNewTextStyles
                weight="font-semibold"
              >
                {heroTalk?.title}
              </Text>
            </div>
            {!isMobileWidth && (
              <div className="mb-2">
                <Text
                  variant="body2"
                  useNewTextStyles
                  tag="p"
                  UNSAFE_className="line-clamp-4"
                >
                  {heroTalk?.description}
                </Text>
              </div>
            )}

            <div className={mainCardRibbonDescription}>
              <Text tag="p" variant="caption1" useNewTextStyles>
                <>{heroTalk?.presenterDisplayName} · </>
                <ViewCount count={heroTalk!.viewedCount}>
                  {formatNumber(heroTalk!.viewedCount, {
                    notation: 'compact',
                    compactDisplay: 'short'
                  })}{' '}
                  plays ·{' '}
                </ViewCount>
                {formatDistanceToNowStrict(new Date(heroTalk!.publishedAt))} ago
              </Text>
            </div>
          </Link>
        </div>

        {ribbonTalks.map((talk, index) => {
          const image = getImageFromSet(talk.video?.primaryImageSet, '16x9');
          const ribbonTalkLink = `/talks/${talk.video?.slug}`;
          const imageSrc = crush(image, { width: IMAGE_WIDTH });

          return (
            <ImageHeroWithRibbonItem
              key={`ribbon-talk-${index}`}
              sectionTitle={title}
              imageSrc={imageSrc}
              ribbonTalkLink={ribbonTalkLink}
              index={index}
              talk={talk}
              classes={{ ribbonCardsClasses }}
              trackClick={trackClick}
              mixpanelTitle={mixpanelTitle}
            />
          );
        })}
      </div>
      {isMobileWidth && (
        <div className="px-5">
          <div className="my-6" />
          <div className="flex w-full justify-end">{primaryLink}</div>
        </div>
      )}
    </ContentContainer>
  );
};

export default ImageHeroWithRibbon;
