import SkeletonItem from '../../SkeletonItem';
import ContentContainer from 'components/content-container';

export const StandardCardSkeleton = ({
  ribbonClasses,
  isMobileWidth,
  ribbonCardsClasses
}) => (
  <ContentContainer className="relative pb-0 pt-10 xl:pt-10">
    <div className="flex items-center justify-between border-t-thin border-black pt-3">
      <SkeletonItem className="h-4 w-32" />
      <SkeletonItem className="h-4 w-32" />
    </div>
    <div className={ribbonClasses}>
      {[...Array(4)].map((_item, index: number) => {
        return (
          <div className={ribbonCardsClasses} key={`data-${index}`}>
            <div className="w-full">
              <SkeletonItem className="h-[180px] w-full" />

              <div className="mt-3">
                <SkeletonItem className="h-4 w-20" />
              </div>
              <div className="mt-2 flex flex-col gap-2">
                <SkeletonItem className="h-4 w-full" />
                <SkeletonItem className="h-4 w-full" />
              </div>

              <div className="mt-2 flex flex-col gap-2">
                <SkeletonItem className="h-4 w-28" />
                <SkeletonItem className="h-4 w-28" />
              </div>
            </div>
          </div>
        );
      })}
    </div>

    {isMobileWidth && (
      <div className="my-6 flex w-full justify-end">
        <SkeletonItem className="h-4 w-12" />
      </div>
    )}
  </ContentContainer>
);

export default StandardCardSkeleton;
