import { Video } from 'api';
import cx from 'classnames';
import { Text } from 'components/@tedui';
import ContentContainer from 'components/content-container';
import { Link } from 'components/router';
import { useEffect, useMemo } from 'react';
import HorizontalCardSkeleton from './Skeleton';

import { HomePageAds, LazyLoadAd } from 'components/ads';
import mixpanel from 'lib/analytics/mixpanel';
import { useDidMount } from 'lib/hooks/useDidMount';
import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';
import usePersonalizedContent from 'lib/hooks/useSailthru/usePersonalizedContent';
import { isEmpty } from 'lodash-es';
import type { LINK_TYPE } from '../HeroSlider';
import HorizontalCardItem from './HorizontalCardItem';

interface HorizontalCardRibbonProps {
  data: Partial<Video>[] | { video: Partial<Video> }[];
  title: string;
  linkText: string;
  linkUrl: string;
  sailthruSectionId?: string;
  isSailthru?: boolean;
  hasSailthruCookie?: boolean;
  mixpanelTitle: string;
  fallbackData?: Partial<Video>[] | { video: Partial<Video> }[];
}

const HorizontalCardRibbon = ({
  data,
  title,
  linkText,
  linkUrl,
  isSailthru,
  sailthruSectionId,
  hasSailthruCookie = false,
  mixpanelTitle,
  fallbackData = []
}: HorizontalCardRibbonProps) => {
  const hasMounted = useDidMount();

  const {
    data: personalizedData,
    refetch: fetchPersonalizedData,
    isFetching: isFetchingPersonalizedData
  } = usePersonalizedContent(sailthruSectionId as string);

  useEffect(() => {
    if (hasSailthruCookie && hasMounted) {
      fetchPersonalizedData();
    }
  }, [fetchPersonalizedData, hasSailthruCookie, hasMounted]);

  const talkData = useMemo(() => {
    if (isSailthru) {
      if (!isEmpty(personalizedData)) {
        return personalizedData.slice(0, 3).map(({ video }) => video);
      }

      if (!isEmpty(data)) {
        return data.map(({ video }) => video);
      }

      return fallbackData;
    }
    return data;
  }, [data, personalizedData, fallbackData]);

  const trackClick = (linkType: LINK_TYPE, itemNumber: number) => {
    mixpanel.track('homepage_click', {
      current_url: window.location.href,
      title: mixpanelTitle,
      item_number: String(itemNumber),
      link_type: linkType,
      value: ''
    });
  };

  const isMobileWidth = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'tui'
  });

  const ribbonClasses = cx('grid', {
    'grid-row-4 mt-6 gap-6': isMobileWidth,
    'grid-row-4 gap-4 divide-y-thin': !isMobileWidth
  });

  const ribbonCardsClasses = cx('flex w-full', {});

  if (isFetchingPersonalizedData) {
    return (
      <HorizontalCardSkeleton
        classes={{
          ribbonClasses
        }}
        isMobileWidth={isMobileWidth}
      />
    );
  }

  if (isEmpty(talkData)) {
    return <></>;
  }

  const titleTakeAction = talkData[0]?.topics?.nodes[0].name;

  const primaryLink = (
    <Link isZenithLink href={linkUrl}>
      <Text
        variant="link2"
        useNewTextStyles
        weight="font-semibold"
        UNSAFE_className="underline"
      >
        {linkText}
      </Text>
    </Link>
  );

  return (
    <ContentContainer className="relative pb-12">
      <div className="grid gap-8 sm-tui:grid-cols-1 lg-tui:grid-cols-3">
        <div className="col-span-2">
          <div className="flex items-center justify-between border-t-thin border-black pt-3">
            <Text variant="label2" weight="font-bold" useNewTextStyles>
              {title} on {titleTakeAction}
            </Text>
            {!isMobileWidth && primaryLink}
          </div>
          <div className={ribbonClasses}>
            {talkData.map((data: Video, index: number) => {
              return (
                <div
                  className={ribbonCardsClasses}
                  key={`Horizontal-card-ribbon-${index}`}
                >
                  <HorizontalCardItem
                    data={data}
                    index={index + 1}
                    trackClick={trackClick}
                    mixpanelTitle={mixpanelTitle}
                  />
                </div>
              );
            })}
          </div>

          {isMobileWidth && (
            <div>
              <div className="my-6 border-t-thin border-[#E5E7EB]" />
              <div className="flex w-full justify-end">{primaryLink}</div>
            </div>
          )}
        </div>

        <div
          onClick={() => {
            mixpanel.track('homepage_click', {
              current_url: window.location.href,
              title: 'ad-abtf',
              item_number: '',
              link_type: 'image',
              value: ''
            });
          }}
        >
          <LazyLoadAd>
            <HomePageAds.HomeAdInlineSky />
          </LazyLoadAd>
        </div>
      </div>
    </ContentContainer>
  );
};

export default HorizontalCardRibbon;
