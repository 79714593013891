import cx from 'classnames';
import mixpanel from 'lib/analytics/mixpanel';
import { useEffect, useRef, useState } from 'react';

import {
  Card,
  CardBody,
  CardDescription,
  CardLabel,
  CardMedia,
  Text
} from 'components/@tedui';
import { Link } from 'components/router';

import getImageFromSet from 'lib/get-image-from-set';
import { useOptimizedImage } from 'lib/hooks/useOptimizedImage';
import {
  abbreviateNumber,
  convertDurationToMinutes,
  formatAsTimestamp
} from 'lib/util';

import { Video } from 'api';
import AnimatedIconButton from 'components/AnimatedIconButton';
import { getBadgeText } from 'components/talk-card/utils';
import { formatDistanceToNowStrict } from 'date-fns';
import { useAuthenticated } from 'lib/auth';
import { ViewCount } from 'lib/business';
import { useIsMobile } from 'lib/hooks/useIsBreakpointWidth/useIsMobile';
import type { LINK_TYPE } from '../../HeroSlider';
import type { EditorialGridItemProps } from './EditorialGridItem.props';

export function EditorialGridItem({
  data,
  wrapperClassName,
  slot,
  contentIndex,
  mixpanelTitle
}: EditorialGridItemProps): React.ReactNode {
  const { isMobile } = useIsMobile({
    checkDevice: false
  });
  const featuredImage = getImageFromSet(data?.primaryImageSet, '16x9');
  const loggedIn = useAuthenticated();

  const [hovered, setHovered] = useState(false);
  const hoverRef = useRef<HTMLDivElement>(null);

  const badgeText = data && getBadgeText((data as Video).type?.name || '');

  const getTitleVariant = (slot: EditorialGridItemProps['slot']) => {
    switch (slot) {
      case 'primary':
        return 'header4';
      case 'secondary':
        return 'header4';
      case 'tertiary':
        return 'subheader2';
      default:
        return 'header1';
    }
  };

  const toggleHover = () => {
    setHovered(prevState => !prevState);
  };

  const { src, blurredSrc, setIsLoading } = useOptimizedImage({
    imageUrl: featuredImage,
    width: 1200,
    height: 675
  });

  const trackClick = (linkType: LINK_TYPE, itemNumber: number) => {
    mixpanel.track('homepage_click', {
      current_url: window.location.href,
      title: mixpanelTitle,
      item_number: String(itemNumber),
      link_type: linkType,
      value: ''
    });
  };

  useEffect(() => {
    if (hoverRef?.current) {
      hoverRef.current.onmouseenter = toggleHover;
      hoverRef.current.onmouseleave = toggleHover;
    }
  }, [hoverRef]);

  const videoLink = `/talks/${data?.slug}`;
  const videoLinkImage = `${videoLink}`;
  const videoLinkTitle = `${videoLink}`;
  const topicLink = `/talks?sort=relevance&topics%5B0%5D=${data?.topics?.nodes[0].name}`;

  return (
    <div className={wrapperClassName}>
      <Card ref={hoverRef}>
        {slot !== 'tertiary' && (
          <Link
            href={videoLinkImage}
            onClick={() => trackClick('image', contentIndex)}
            isZenithLink
          >
            <CardMedia
              image={{
                src: src || blurredSrc,
                alt: data?.title,
                sizes:
                  '(min-width: 640px) 100vw, (min-width: 768px) 50vw, (min-width: 1024px) 33vw'
              }}
              badges={{
                bottomRight: {
                  text: convertDurationToMinutes(
                    formatAsTimestamp(data?.duration)
                  )
                },
                ...(badgeText && {
                  bottomLeft: {
                    backgroundColor: 'white',
                    isDarkBackground: false,
                    text: isMobile ? badgeText.small : badgeText.large
                  }
                })
              }}
              blurUntilLoaded
              setIsLoading={setIsLoading}
              showPlayIcon={hovered}
            />
          </Link>
        )}

        <CardBody
          addHorizontalPadding={false}
          UNSAFE_className={cx({
            'mt-2': slot !== 'tertiary',
            '!p-0': slot === 'tertiary'
          })}
        >
          <div className="flex items-center justify-between">
            <Link
              href={topicLink}
              onClick={() => trackClick('topic', contentIndex)}
              isZenithLink
            >
              <CardLabel
                isBold={false}
                color={{
                  override: '#EB0028'
                }}
                testId={`editorial-grid-item-${slot}-topic`}
              >
                {data?.topics?.nodes[0].name}
              </CardLabel>
            </Link>
            {loggedIn && (
              <div className="mb-2">
                <AnimatedIconButton
                  talk={data}
                  mixpanelTitle={mixpanelTitle}
                  itemNumber={String(contentIndex)}
                />
              </div>
            )}
          </div>
          <Link
            href={videoLinkTitle}
            onClick={() => trackClick('title', contentIndex)}
            isZenithLink
          >
            <Text
              tag="p"
              variant={getTitleVariant(slot)}
              useNewTextStyles
              weight="font-semibold"
              UNSAFE_className="mb-2"
            >
              {data?.title}
            </Text>

            {slot !== 'tertiary' && (
              <div
                className={cx({
                  'hidden md-tui:block': slot === 'secondary'
                })}
              >
                <CardDescription
                  testId={`editorial-grid-item-${slot}-description`}
                >
                  {data?.description}
                </CardDescription>
              </div>
            )}

            <div className="flex flex-col leading-tui-lg">
              <Text tag="p" variant="caption1" useNewTextStyles>
                <>{data?.presenterDisplayName} · </>
                <ViewCount count={data.viewedCount}>
                  {`${abbreviateNumber(data.viewedCount)}`} plays ·{' '}
                </ViewCount>
                {formatDistanceToNowStrict(new Date(data!.publishedAt))} ago
              </Text>
            </div>
          </Link>
        </CardBody>
      </Card>
    </div>
  );
}
