import { EditorialGridItem } from '../EditorialGridItem';
import { SailthruNodesData } from '../EditorialGrid.props';

interface EditorialOneColumnProps {
  talks: SailthruNodesData[];
  mixpanelTitle?: string;
}

const EditorialOneColumn = ({
  talks,
  mixpanelTitle
}: EditorialOneColumnProps) => {
  return (
    <>
      {/* 639 - 320 */}
      <div
        id="editorial-grid-one-column"
        data-testid="editorial-grid-one-column"
        className="grid grid-cols-1 gap-4 divide-y-thick"
      >
        {talks.map((item, index) => (
          <div key={item.slug} className="pt-4 first:pt-0">
            <EditorialGridItem
              slot="tertiary"
              data={item}
              contentIndex={index + 1}
              mixpanelTitle={mixpanelTitle}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default EditorialOneColumn;
