import ContentContainer from 'components/content-container';
import Divider from 'components/divider';
import HomepageSpotlight from './HomepageSpotlight';

import { Video } from 'api';
import StandardCard from '../StandardRibbon/StandardCard';

interface HomepageFeaturedSpotlightProps {
  spotLightData: Video;
  ribbonData?: Video[];
}

const HomepageFeaturedSpotlight = ({
  spotLightData,
  ribbonData
}: HomepageFeaturedSpotlightProps) => {
  return (
    <ContentContainer className="relative pb-12 pt-8">
      <HomepageSpotlight spotLightData={spotLightData} />

      <Divider className="bg-textPrimary-onLight" />

      <div className="-ml-5 mt-5 grid grid-cols-4 gap-5 divide-x-thin divide-[rgba(18,18,18,0.16)]">
        {ribbonData.map((data: Video, index: number) => (
          <div className="flex w-full pl-5" key={`homepage_featured-${index}`}>
            <StandardCard
              data={data}
              index={index + 2}
              mixpanelTitle={'editorial-section'}
            />
          </div>
        ))}
      </div>
    </ContentContainer>
  );
};

export default HomepageFeaturedSpotlight;
