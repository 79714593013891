import Head from 'next/head';
import * as React from 'react';

const HomepageHead = (): React.ReactNode => (
  <Head>
    <meta
      name="description"
      content="TED Talks are influential videos from expert speakers on education, business, science, tech and creativity, with subtitles in 100+ languages. Ideas free to stream and download."
    />
    <meta
      property="og:image"
      content="https://pi.tedcdn.com/r/pl.tedcdn.com/social/ted-logo-fb.png?v=wAff13s?"
    />
    <meta property="og:title" content="TED: Ideas change everything" />
    <meta
      property="og:description"
      content="TED Talks are influential videos from expert speakers on education, business, science, tech and creativity, with subtitles in 100+ languages. Ideas free to stream and download."
    />
    <meta property="og:url" content="https://www.ted.com/" />
    <meta
      name="keywords"
      content="TED, Talks, Themes, Speakers, Technology, Entertainment, Design"
    />
  </Head>
);

export default HomepageHead;
