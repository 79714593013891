import cx from 'classnames';
import { Button, Icon, Text } from 'components/@tedui';
import { isNil } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useNavUserInformationQuery } from 'api';
import SubscribeCard from 'components/NewsletterRibbon/NewsletterRibbonCard';
import ContentContainer from 'components/content-container';
import { Honeypot } from 'components/footer/Honeypot';
import { Link } from 'components/router';
import ErrorIcon from 'icons/AlertTraingleIcon';
import SuccessIcon from 'icons/CheckIcon';
import { getSystemLanguage } from 'lib/analytics/mixpanel-helpers';
import { useAuthenticated } from 'lib/auth';
import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';
import { useSailthru } from 'lib/hooks/useSailthru';
import { RIBBON_SUBSCRIPTION_MAP, SOURCES } from 'lib/hooks/useSailthru/const';
import getSailthruVars from 'lib/sailthru/request-user-var-sailthru';
import { useRouter } from 'next/router';
import { CardState } from './NewsletterRibbonForm.props';
import { initialCardState } from './constants';

const FAILED_ERROR_MESSAGE = 'Something went wrong. Please try again later.';

const NewsletterRibbonLabels = {
  subscribed: {
    title: 'You are subscribed to TED Talks Daily!'
  },
  notSubscribed: {
    title: 'Get talks sent to your inbox based on your interests.'
  },
  privacyPolicy: 'By subscribing, you accept the terms of '
};

function NewsletterRibbonForm(): React.ReactNode {
  const { pathname } = useRouter();

  const {
    register,
    setValue,
    formState: { errors, isValid, touchedFields, isDirty },
    getValues
  } = useForm({
    mode: 'onChange'
  });

  const isTablet = useIsBreakpointWidth({ size: 'lg', breakPointType: 'tui' });
  const isMobile = useIsBreakpointWidth({ size: 'md', breakPointType: 'tui' });
  const isPhone = useIsBreakpointWidth({ size: 'sm', breakPointType: 'tui' });
  const sailthru = useSailthru();
  const isLoggedIn = useAuthenticated();
  const { loading: isLoadingUserEmail, data } = useNavUserInformationQuery({
    ssr: true,
    skip: !isLoggedIn
  });

  const [cards, setCards] =
    useState<Record<string, CardState>>(initialCardState);

  const [honeypotChecked, setHoneypotChecked] = useState(false);
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(false);
  const [hasSubscribed, setHasSubscribed] = useState(false);
  const [failedSubscription, setFailedSubscription] = useState(false);

  const selectedCards = useMemo(
    () => Object.keys(cards).filter(cardId => cards[cardId].isSelected),
    [cards]
  );

  const isSubscriptionDisabled = useMemo(
    () =>
      (selectedCards.length === 0 && !hasSubscribed) ||
      isLoadingSubscription ||
      isLoadingUserEmail,
    [selectedCards, hasSubscribed, isLoadingSubscription, isLoadingUserEmail]
  );

  const inputWrapperStyles = cx(
    'relative w-full rounded-sm border-thick bg-white text-tui-sm leading-[130%] tracking-[-0.21px] sm-tui:mr-5 sm-tui:w-1/2 lg-tui:mr-0 lg-tui:mt-12 lg-tui:w-full',
    {
      'border-[#62BAAA]': touchedFields.email && isValid,
      'border-[#F01C64]': !!errors.email
    }
  );
  const inputStyles = cx(
    'newsletter-input relative w-full rounded-sm border-none px-4 pb-1 pt-4 outline-none',
    {
      'is-valid': touchedFields.email || isDirty
    }
  );

  const toggleCardSelection = (key: string) => {
    const newCards = { ...cards };
    newCards[key].isSelected = !newCards[key].isSelected;
    setCards(newCards);
  };

  const onSuccess = useCallback(async () => {
    setHasSubscribed(true);
    setIsLoadingSubscription(false);
    setFailedSubscription(false);

    const email = getValues().email;
    await fetchUserProfile(email);
  }, [getValues]);

  const onError = useCallback(() => {
    setIsLoadingSubscription(false);
    setFailedSubscription(true);
  }, []);

  const onSubscribe = useCallback(
    (email: string, selected: string[]) => {
      sailthru.multiNewsletterSignup({
        email,
        newsletterNames: selected,
        source: pathname.includes('newsletter')
          ? SOURCES.NEWSLETTER_MODULE
          : SOURCES.HOMEPAGE_MODULE,
        onSuccess,
        onError
      });
    },
    [sailthru, onError, onSuccess]
  );

  const handleSubscribe = useCallback(() => {
    if (!honeypotChecked) {
      const subscription = [...selectedCards];
      const email = getValues().email;

      setIsLoadingSubscription(true);
      onSubscribe(email, subscription);
    }
  }, [selectedCards, honeypotChecked, onSubscribe, cards, getValues]);

  const updateSubscriptionInfo = vars => {
    const updatedCards = { ...initialCardState };

    Object.keys(initialCardState).forEach(
      cardId => {
        const subscription = RIBBON_SUBSCRIPTION_MAP[cardId];
        updatedCards[cardId].isSelected = false;
        Object.entries(subscription).forEach(
          ([key]) => {
            if (key.endsWith('_subscriber')) {
              updatedCards[cardId].isSubscribed = vars[key];
            }
          },
          [updatedCards]
        );
      },
      [updatedCards]
    );

    return updatedCards;
  };

  const fetchUserProfile = useCallback(async userEmail => {
    try {
      const data = await getSailthruVars({ id: userEmail });

      if (data?.vars) {
        const subscriptionInfo = updateSubscriptionInfo(data.vars);
        setCards({ ...subscriptionInfo });
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      const isNullEmail = isNil(data?.viewer?.primaryEmailAddress);

      if (!isNullEmail) {
        setValue('email', data?.viewer?.primaryEmailAddress, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true
        });
        fetchUserProfile(data?.viewer?.primaryEmailAddress);
      }
    }
  }, [isLoggedIn, fetchUserProfile, data]);

  const onCloseMessage = useCallback(async () => {
    setHasSubscribed(false);
  }, []);

  const getNewsletterHeader = useCallback(() => {
    return (
      <>
        <Text
          useNewTextStyles
          tag="h3"
          variant="header3"
          weight="font-semibold"
          color={{ isOnLightSurface: false }}
          testId="NewsletterRibbonTitle_TESTID"
        >
          Newsletters
        </Text>

        <Text
          useNewTextStyles
          variant="body1"
          tag="span"
          color={{ isOnLightSurface: false }}
        >
          {NewsletterRibbonLabels.notSubscribed.title}
        </Text>
      </>
    );
  }, []);

  const getSubscribedMessage = useCallback(
    () => (
      <div
        data-testid="newsletter_ribbon_success"
        className="relative flex h-48 w-full flex-col justify-center md-tui:h-80 lg-tui:h-auto lg-tui:py-0 lg-tui:pl-24"
      >
        <button
          type="button"
          className="absolute right-0 top-0 h-7 lg-tui:mr-16"
          onClick={onCloseMessage}
        >
          <Icon
            iconName="x"
            className="text-tui-4xl text-textTertiary-onLight"
          />
        </button>
        <Text variant="header2" color={{ isOnLightSurface: false }}>
          Thanks for subscribing!
        </Text>
      </div>
    ),
    []
  );

  return (
    <ContentContainer className="bg-textSecondary-onLight md:px-5 lg:px-10">
      <div
        className="flex w-full flex-col justify-center py-14 lg-tui:flex-row lg-tui:py-20"
        data-testid="NewsletterForm_TESTID"
      >
        <div className="flex w-full flex-col gap-2 lg-tui:hidden">
          {getNewsletterHeader()}
        </div>

        <div className="my-8 flex flex-wrap justify-between gap-4 border-y-thin border-gray-700 pt-12 sm-tui:hidden">
          {hasSubscribed
            ? getSubscribedMessage()
            : Object.entries(cards).map(([key, card], index) => (
                <div
                  key={card.newsletter_id}
                  className="mb-12 xs-tui:w-[46%] sm-tui:w-[48%]"
                >
                  <SubscribeCard
                    testId={`subscribe-btn-${index}`}
                    hideSeparator
                    card={card}
                    onClick={() => toggleCardSelection(key)}
                  />
                </div>
              ))}
        </div>
        <div className="my-16 hidden justify-between border-gray-700 md:flex sm-tui:flex lg-tui:my-0 lg-tui:mr-5 lg-tui:flex-[5] xl-tui:flex-[2]">
          {hasSubscribed
            ? getSubscribedMessage()
            : Object.entries(cards).map(([key, card], index) => (
                <SubscribeCard
                  testId={`subscribe-btn-${index}`}
                  key={card.newsletter_id}
                  card={card}
                  onClick={() => toggleCardSelection(key)}
                />
              ))}
        </div>
        <div className="flex flex-col lg-tui:flex-[2] xl-tui:flex-[1]">
          <div className="mb-8 flex flex-col items-center sm-tui:flex-row lg-tui:flex-col lg-tui:items-start">
            <div className="hidden w-full flex-col gap-2 lg-tui:flex">
              {getNewsletterHeader()}
            </div>

            <div className={inputWrapperStyles}>
              <Honeypot onGrab={setHoneypotChecked} />
              <input
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Please enter a valid email address'
                  }
                })}
                className={inputStyles}
                data-testid="newsletter-ribbon-input"
              />
              <label htmlFor="email" className="newsletter-input">
                What is your email?
              </label>
              {errors.email && (
                <ErrorIcon className="absolute right-2 top-1/2 mr-2 size-4 -translate-y-1/2 transform text-tui-xs" />
              )}

              {touchedFields.email && isValid && (
                <SuccessIcon
                  variant="circle-fill"
                  className="absolute right-2 top-1/2 mr-2 size-5 -translate-y-1/2 transform text-tui-xs"
                />
              )}
            </div>
            {errors.email && (
              <Text
                data-testid="newsletter_ribbon_error"
                UNSAFE_className="mt-1"
                variant="body2"
                color={{ color: 'error' }}
              >
                {errors.email?.message?.toString()}
              </Text>
            )}
            {failedSubscription && (
              <Text UNSAFE_className="mt-1" color={{ color: 'error' }}>
                {FAILED_ERROR_MESSAGE}
              </Text>
            )}
            <Button
              UNSAFE_className="mt-6 h-11 w-full px-10 py-6 sm-tui:mt-0 sm-tui:w-56 lg-tui:mt-6  lg-tui:w-full xl-tui:w-56"
              text={isLoadingSubscription ? 'Loading...' : 'Subscribe now'}
              isDarkBackground
              isFullWidth={(!isTablet && !isMobile) || isPhone}
              testId="Newsletter_Ribbon_Subscribe"
              variant="primary"
              onClick={handleSubscribe}
              disabled={!!isSubscriptionDisabled || !isValid || !!errors.email}
              textProps={{
                variant: 'body2',
                useNewTextStyles: true
              }}
            />
          </div>
          <Text
            useNewTextStyles
            variant="body2"
            tag="span"
            color={{ isOnLightSurface: false }}
          >
            {NewsletterRibbonLabels.privacyPolicy}
            <Link
              href="/about/our-organization/our-policies-terms/privacy-policy"
              className="underline"
              onClick={() => {
                mixpanel.track('global_footer_click', {
                  event_interaction_context: 'newsletters_privacy-policy',
                  system_language: getSystemLanguage()
                });
              }}
            >
              TED's Privacy Policy
            </Link>
          </Text>
        </div>
      </div>
    </ContentContainer>
  );
}

export default NewsletterRibbonForm;
