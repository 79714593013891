import { SailthruNodesData } from '../EditorialGrid.props';
import { EditorialGridItem } from '../EditorialGridItem';

interface EditorialTwoColumnsProps {
  talks: SailthruNodesData[];
  mixpanelTitle?: string;
}

const EditorialTwoColumns = ({
  talks,
  mixpanelTitle
}: EditorialTwoColumnsProps) => {
  return (
    <>
      {/* 767 - 640 */}
      <div
        id="editorial-grid-two-columns"
        data-testid="editorial-grid-two-columns"
        className="invisible h-0 sm-tui:visible sm-tui:h-min md-tui:invisible md-tui:h-0"
      >
        <div className="grid grid-cols-2 gap-10">
          <div
            id="editorial-grid-items-first"
            data-testid="editorial-grid-items-first"
            className="grid grid-rows-3"
          >
            {talks.slice(0, 3).map((item, index) => (
              <div key={item.slug}>
                <EditorialGridItem
                  contentIndex={index + 1}
                  slot="tertiary"
                  data={item}
                  wrapperClassName={index !== 0 ? 'py-4 border-t-thin' : 'py-4'}
                  mixpanelTitle={mixpanelTitle}
                />
              </div>
            ))}
          </div>

          <div
            id="editorial-grid-items-last"
            data-testid="editorial-grid-items-last"
            className="grid grid-rows-3"
          >
            {talks.slice(3, 6).map((item, index) => (
              <div key={item.slug}>
                <EditorialGridItem
                  contentIndex={index + 4}
                  slot="tertiary"
                  data={item}
                  wrapperClassName={index !== 0 ? 'py-4 border-t-thin' : 'py-4'}
                  mixpanelTitle={mixpanelTitle}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditorialTwoColumns;
