import {
  type HomePageDataQuery,
  HomePageDataDocument,
  Playlist,
  Ribbon,
  Video
} from 'api';
import { sortBy } from 'f';
import { initializeApollo } from 'lib/graphql/client';
import { createLogger } from 'lib/logging';
import {
  TrendingData,
  fetchPlatformData,
  fetchTrendingDataFromS3
} from 'lib/pages/homepage/utils';
import type { GetStaticProps } from 'next';
import dynamic from 'next/dynamic';
import { createClient } from 'prismicio';
import { InstantSearch } from 'react-instantsearch';

import { CustomConfigure, mainIndex, searchClient } from 'components/SearchKit';
import { HomePageAds, LazyLoadAd } from 'components/ads';
import { Layout } from 'components/layout';
import Homepage from 'components/pages/homepage';
import {
  SailthruDataContentType,
  fetchSailthruData,
  getSailthruFallbackData
} from 'lib/sailthru';
import { deduplicateSailthruContent } from 'lib/sailthru/dedupeContent';
import { HomepageDocument } from 'prismic-types';

// Dynamically import heavy components
const TopicsModalProvider = dynamic(
  () =>
    import('components/pages/talks/TalkIndex/Modals').then(
      mod => mod.TopicsModalProvider
    ),
  { ssr: false }
);

const TopicsModal = dynamic(
  () =>
    import('components/pages/talks/TalkIndex/Modals').then(
      mod => mod.TopicsModal
    ),
  { ssr: false }
);

const apolloClient = initializeApollo();

type HomeProps = {
  programmerRibbons: Array<Ribbon>;
  newestTalks: Array<Video>;
  playlists: Array<Playlist>;
  prismicPage: HomepageDocument;
  sailthruDataContent: SailthruDataContentType;
  sailthruFallback: SailthruDataContentType;
};

export default function Home({
  programmerRibbons,
  prismicPage,
  sailthruDataContent,
  sailthruFallback
}: HomeProps): React.ReactNode {
  return (
    <div>
      <LazyLoadAd showLoader={false}>
        <HomePageAds.HomeAdBilllBoardATF />
      </LazyLoadAd>
      <Layout>
        <InstantSearch
          searchClient={searchClient}
          indexName={mainIndex}
          future={{ preserveSharedStateOnUnmount: true }}
        >
          <TopicsModalProvider>
            <CustomConfigure />
            <TopicsModal />
            <Homepage
              programmerRibbons={programmerRibbons}
              prismicPage={prismicPage}
              sailthruDataContent={sailthruDataContent}
              sailthruFallback={sailthruFallback}
            />
          </TopicsModalProvider>
        </InstantSearch>
      </Layout>
    </div>
  );
}

export const getStaticProps: GetStaticProps = async ({ previewData }) => {
  const log = createLogger('getStaticProps');

  try {
    const [trendingData, prismicPage, sailthruDataContent] = (await Promise.all(
      [
        fetchTrendingDataFromS3().catch(err => {
          log.error('Fetching trending data failed', err);
          return { trendingTalks: [] } as TrendingData;
        }),
        createClient({ previewData }).getSingle('homepage'),
        fetchSailthruData()
      ]
    )) as [TrendingData, HomepageDocument, SailthruDataContentType];

    const sailthruFallback = await getSailthruFallbackData(sailthruDataContent);

    const queryResult = await apolloClient.query({
      query: HomePageDataDocument,
      variables: {
        trendingIds: trendingData.trendingTalks
          .map((t: { talkId: string }) => t.talkId)
          .slice(0, 17)
      }
    });

    const data: HomePageDataQuery = queryResult.data as HomePageDataQuery;
    const { programmerRibbons } = await fetchPlatformData(data);

    const featuredSpotlightDataIds = sortBy(
      video => video.order,
      programmerRibbons[0]?.slots.nodes ?? []
    )
      .map(node => node.video)
      .slice(0, 5)
      .map(t => t.id);

    const sailthru = deduplicateSailthruContent(
      sailthruDataContent,
      featuredSpotlightDataIds
    );

    return {
      props: {
        programmerRibbons,
        prismicPage,
        sailthruDataContent: sailthru,
        sailthruFallback
      },
      revalidate: 180
    };
  } catch (error) {
    log.error('Error in getStaticProps', error);
    return {
      notFound: true,
      revalidate: 30
    };
  }
};
