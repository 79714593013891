import type { JSX } from 'react';

type AlertTriangleIconProps = {
  className?: string;
};

const AlertTriangleIcon = (props: AlertTriangleIconProps): JSX.Element => {
  const { className } = props;
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <g clip-path="url(#clip0_1744_84524)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.56592 0.235792C6.84048 0.0812093 7.15025 0 7.46535 0C7.78044 0 8.09021 0.0812093 8.36477 0.235792C8.63934 0.390375 8.86943 0.613113 9.03285 0.882515L9.03429 0.88489L14.6851 10.3184C14.8451 10.5957 14.9298 10.91 14.9307 11.2301C14.9316 11.5502 14.8486 11.865 14.6901 12.1431C14.5316 12.4212 14.303 12.653 14.0271 12.8153C13.7512 12.9777 13.4376 13.0649 13.1175 13.0685L13.112 13.0685L1.81318 13.0685C1.49308 13.065 1.17948 12.9777 0.903583 12.8153C0.627682 12.653 0.399099 12.4212 0.240575 12.1431C0.0820506 11.865 -0.000889146 11.5502 7.18864e-06 11.2301C0.000903523 10.91 0.0856049 10.5957 0.245684 10.3185L0.24969 10.3115L5.89641 0.884894L6.32535 1.14183L5.89785 0.882515C6.06126 0.613113 6.29135 0.390375 6.56592 0.235792ZM7.46484 4.06836C7.74099 4.06836 7.96484 4.29222 7.96484 4.56836V7.23503C7.96484 7.51117 7.74099 7.73503 7.46484 7.73503C7.1887 7.73503 6.96484 7.51117 6.96484 7.23503V4.56836C6.96484 4.29222 7.1887 4.06836 7.46484 4.06836ZM6.96484 9.90234C6.96484 9.6262 7.1887 9.40234 7.46484 9.40234H7.47151C7.74765 9.40234 7.97151 9.6262 7.97151 9.90234C7.97151 10.1785 7.74765 10.4023 7.47151 10.4023H7.46484C7.1887 10.4023 6.96484 10.1785 6.96484 9.90234Z"
          fill="#F01C64"
        />
      </g>
      <defs>
        <clipPath id="clip0_1744_84524">
          <rect width="15" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default AlertTriangleIcon;
