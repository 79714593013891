import SkeletonItem from '../../SkeletonItem';

export const EditorialSkeleton = () => {
  return (
    <>
      {/* 1280 - 1024 */}
      <div
        id="editorial-grid-three-columns"
        data-testid="editorial-grid-three-columns"
        className="invisible grid h-0 grid-cols-12 gap-5 divide-x-thin lg-tui:visible lg-tui:h-min"
      >
        <div className="col-span-5">
          <SkeletonItem className="h-60 w-full" />
          <SkeletonItem className="my-4 h-4 w-28" />
          <SkeletonItem className="my-4 h-10 w-full" />
          <SkeletonItem className="my-2 h-4 w-full" />
          <SkeletonItem className="my-2 h-4 w-full" />
          <SkeletonItem className="my-2 h-4 w-full" />
          <SkeletonItem className="mt-4 h-4 w-20" />
        </div>
        <div className="col-span-4 pl-5">
          <SkeletonItem className="h-40 w-full" />
          <SkeletonItem className="my-4 h-4 w-28" />
          <SkeletonItem className="my-4 h-10 w-full" />
          <SkeletonItem className="my-2 h-4 w-full" />
          <SkeletonItem className="my-2 h-4 w-full" />
          <SkeletonItem className="my-2 h-4 w-full" />
          <SkeletonItem className="mt-4 h-4 w-20" />
        </div>
        <div
          id="editorial-grid-item-tertiary"
          data-testid="editorial-grid-item-tertiary"
          className="col-span-3 pl-5"
        >
          {[...Array(3)].map((_item, index) => (
            <div className="mb-8" key={`section-0-${index}`}>
              <SkeletonItem className="mb-4 h-4 w-28" />
              <SkeletonItem className="my-4 h-4 w-full" />
              <SkeletonItem className="h-4 w-full" />
              <SkeletonItem className="my-4 h-4 w-full" />
            </div>
          ))}
        </div>
      </div>

      {/* 1023 - 768 */}
      <div
        id="editorial-grid-two-with-image-columns"
        data-testid="editorial-grid-two-with-image-columns"
        className="invisible h-0 md-tui:visible md-tui:h-min lg-tui:invisible lg-tui:h-0"
      >
        <div className="-ml-5 grid grid-cols-2 gap-5 divide-x-thin pb-8">
          <div className="pl-5">
            <SkeletonItem className="h-40 w-full" />
            <SkeletonItem className="my-4 h-4 w-28" />
            <SkeletonItem className="my-4 h-10 w-full" />
            <SkeletonItem className="my-2 h-4 w-full" />
            <SkeletonItem className="my-2 h-4 w-full" />
            <SkeletonItem className="my-2 h-4 w-full" />
            <SkeletonItem className="mt-4 h-4 w-20" />
          </div>
          <div className="pl-5">
            <SkeletonItem className="h-40 w-full" />
            <SkeletonItem className="my-4 h-4 w-28" />
            <SkeletonItem className="my-4 h-10 w-full" />
            <SkeletonItem className="my-2 h-4 w-full" />
            <SkeletonItem className="my-2 h-4 w-full" />
            <SkeletonItem className="my-2 h-4 w-full" />
            <SkeletonItem className="mt-4 h-4 w-20" />
          </div>
        </div>

        <div className="grid grid-cols-2">
          <div className="flex flex-col gap-5 pr-5">
            {[...Array(2)].map((_item, index) => (
              <div className="mb-8" key={`section-1-${index}`}>
                <SkeletonItem className="mb-4 h-4 w-28" />
                <SkeletonItem className="my-4 h-4 w-full" />
                <SkeletonItem className="h-4 w-full" />
                <SkeletonItem className="my-4 h-4 w-full" />
              </div>
            ))}
          </div>

          <div className="relative">
            <div className="absolute left-0 top-1/2 h-[88%] -translate-y-1/2 transform border-l-thin" />
            <div className="flex flex-col gap-5 pl-5">
              {[...Array(2)].map((_item, index) => (
                <div className="mb-8" key={`section-2-${index}`}>
                  <SkeletonItem className="mb-4 h-4 w-28" />
                  <SkeletonItem className="my-4 h-4 w-full" />
                  <SkeletonItem className="h-4 w-full" />
                  <SkeletonItem className="my-4 h-4 w-full" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* 767 - 640 */}
      <div
        id="editorial-grid-two-columns"
        data-testid="editorial-grid-two-columns"
        className="invisible h-0 sm-tui:visible sm-tui:h-min md-tui:invisible md-tui:h-0"
      >
        <div className="grid grid-cols-2 gap-10">
          <div
            id="editorial-grid-items-first"
            data-testid="editorial-grid-items-first"
            className="grid grid-rows-3"
          >
            {[...Array(3)].map((_item, index) => (
              <div className="mb-8" key={`section-3-${index}`}>
                <SkeletonItem className="mb-4 h-4 w-28" />
                <SkeletonItem className="my-4 h-4 w-full" />
                <SkeletonItem className="h-4 w-full" />
                <SkeletonItem className="my-4 h-4 w-full" />
              </div>
            ))}
          </div>

          <div
            id="editorial-grid-items-last"
            data-testid="editorial-grid-items-last"
            className="grid grid-rows-3"
          >
            {[...Array(3)].map((_item, index) => (
              <div className="mb-8" key={`section-4-${index}`}>
                <SkeletonItem className="mb-4 h-4 w-28" />
                <SkeletonItem className="my-4 h-4 w-full" />
                <SkeletonItem className="h-4 w-full" />
                <SkeletonItem className="my-4 h-4 w-full" />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div
        id="editorial-grid-one-column"
        data-testid="editorial-grid-one-column"
        className="invisible grid h-0 grid-cols-1 gap-4 divide-y-thick xs-tui:visible xs-tui:h-min sm-tui:invisible sm-tui:h-0"
      >
        {[...Array(6)].map(() => (
          <div key={Math.random()}>
            <SkeletonItem className="my-4 h-4 w-28" />
            <SkeletonItem className="my-4 h-4 w-full" />
            <SkeletonItem className="h-4 w-full" />
            <SkeletonItem className="my-4 h-4 w-full" />
          </div>
        ))}
      </div>
    </>
  );
};

export default EditorialSkeleton;
