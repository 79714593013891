import cx from 'classnames';
import { Text } from 'components/@tedui';
import ContentContainer from 'components/content-container';
import { useDidMount } from 'lib/hooks/useDidMount';
import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';
import usePersonalizedContent from 'lib/hooks/useSailthru/usePersonalizedContent';
import { isEmpty } from 'lodash-es';
import { useEffect, useMemo, type JSX } from 'react';
import NumberedCard from './NumberedCard/NumberedCard';
import Skeleton from './Skeleton/NumberedCardRibbonSkeleton';
import { Video } from 'api';
import { Recommendation } from 'lib/sailthru';

type NumberedCardRibbonProps = {
  talks: { video: Partial<Video> }[];
  title: string;
  isSailthru?: boolean;
  sailthruSectionId?: string;
  hasSailthruCookie?: boolean;
  mixpanelTitle: string;
  fallbackData?: Recommendation[];
};

const NumberedCardRibbon = ({
  talks,
  title,
  isSailthru,
  sailthruSectionId,
  hasSailthruCookie = false,
  mixpanelTitle,
  fallbackData = []
}: NumberedCardRibbonProps): JSX.Element => {
  const hasContent = !isEmpty(talks);
  const hasMounted = useDidMount();

  const {
    data: personalizedData,
    refetch: fetchPersonalizedData,
    isFetching: isFetchingPersonalizedData
  } = usePersonalizedContent(sailthruSectionId);

  useEffect(() => {
    if (hasSailthruCookie && hasMounted) {
      fetchPersonalizedData();
    }
  }, [fetchPersonalizedData, hasSailthruCookie, hasMounted]);

  const talkData = useMemo(() => {
    if (isSailthru) {
      if (!isEmpty(personalizedData)) {
        return personalizedData.slice(0, 6).map(({ video }) => video);
      }

      if (hasContent) {
        return talks.map(({ video }) => video);
      }

      return fallbackData;
    }
    return talks;
  }, [talks, fallbackData, hasContent, personalizedData]);

  const isMobileWidth = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'tui'
  });

  const headerClasses = cx('flex border-t-thin border-black', {
    'pb-4': !isMobileWidth,
    'pb-6': isMobileWidth
  });
  const cardClasses = cx('grid rounded-sm', {
    'grid-cols-1 gap-0 divide-y-thin': isMobileWidth,
    'grid-cols-2 gap-x-10 gap-y-4': !isMobileWidth
  });

  if (isFetchingPersonalizedData) {
    return (
      <Skeleton headerClasses={headerClasses} isMobileWidth={isMobileWidth} />
    );
  }

  if (isEmpty(talkData)) {
    return <></>;
  }

  return (
    <ContentContainer className="relative pb-12">
      <div id="slide-header" className={headerClasses}>
        <Text
          tag="p"
          isBold
          variant="label2"
          useNewTextStyles
          UNSAFE_className="pt-3"
        >
          {title}
        </Text>
      </div>

      <div className={cardClasses}>
        {talkData.map((talk, index) => {
          const talkOrder = {
            0: 1,
            1: 4,
            2: 2,
            3: 5,
            4: 3,
            5: 6
          };
          const number = isMobileWidth ? index + 1 : talkOrder[index];
          return (
            <NumberedCard
              key={`Numbered-${number}`}
              talk={talk}
              talkNumber={number}
              mixpanelTitle={mixpanelTitle}
            />
          );
        })}
      </div>
    </ContentContainer>
  );
};

export default NumberedCardRibbon;
