// Code generated by Slice Machine. DO NOT EDIT.

import dynamic from 'next/dynamic';

export const components = {
  block_quote: dynamic(() => import('./BlockQuote')),
  body: dynamic(() => import('./Body')),
  button: dynamic(() => import('./Button')),
  card_w_ith_description: dynamic(() => import('./CardWIthDescription')),
  carousel: dynamic(() => import('./Carousel')),
  comparison_table: dynamic(() => import('./ComparisonTable')),
  dubbing_newsletter: dynamic(() => import('./Newsletter')),
  featured_link: dynamic(() => import('./FeaturedLink')),
  featured_spotlight: dynamic(() => import('./FeaturedSpotlight')),
  flickr_feed: dynamic(() => import('./FlickrFeed')),
  footnote: dynamic(() => import('./Footnote')),
  gallery: dynamic(() => import('./Gallery')),
  game_promo_banner: dynamic(() => import('./GamePromoBanner')),
  games_about_ribbon: dynamic(() => import('./GamesAboutRibbon')),
  games_ad_slot: dynamic(() => import('./GamesAdSlot')),
  games_feature: dynamic(() => import('./GamesFeature')),
  games_here_to_play: dynamic(() => import('./GamesHereToPlay')),
  games_hero: dynamic(() => import('./GamesHero')),
  games_newsletter: dynamic(() => import('./GamesNewsletter')),
  games_video_feature: dynamic(() => import('./GamesVideoFeature')),
  grid_card: dynamic(() => import('./GridCard')),
  heading: dynamic(() => import('./Heading')),
  hero: dynamic(() => import('./Hero')),
  hero_copy: dynamic(() => import('./HeroCopy')),
  hero_image_with_text_overlay: dynamic(
    () => import('./HeroImageWithTextOverlay')
  ),
  hero_ted_ed: dynamic(() => import('./HeroTedEd')),
  horizontal_line: dynamic(() => import('./HorizontalLine')),
  horizontal_rule: dynamic(() => import('./HorizontalRule')),
  html: dynamic(() => import('./Html')),
  iframe: dynamic(() => import('./Iframe')),
  image: dynamic(() => import('./Image')),
  image_with_wrapped_text: dynamic(() => import('./ImageWithWrappedText')),
  img_sequence: dynamic(() => import('./GridImage')),
  img_with_text: dynamic(() => import('./ImgWithSideText')),
  languages: dynamic(() => import('./Languages')),
  large_copy: dynamic(() => import('./LargeCopy')),
  list: dynamic(() => import('./List')),
  listing_table: dynamic(() => import('./ListingTable')),
  markdown: dynamic(() => import('./Markdown')),
  membership_spotlight: dynamic(() => import('./ImageCallToAction')),
  newsletter_subscription: dynamic(() => import('./NewsletterSubscription')),
  our_impact: dynamic(() => import('./TitleWithDesciption')),
  partnerships_trending_slice: dynamic(
    () => import('./PartnershipsTrendingSlice')
  ),
  promo_box: dynamic(() => import('./PromoBox')),
  questions_with_playlists: dynamic(() => import('./QuestionsWithPlaylists')),
  rss_feed: dynamic(() => import('./RssFeed')),
  single_card: dynamic(() => import('./SingleCard')),
  social_sharing: dynamic(() => import('./SocialSharing')),
  space: dynamic(() => import('./Space')),
  talk_list: dynamic(() => import('./TalkList')),
  talks_feed: dynamic(() => import('./TalksFeed')),
  title_with_links: dynamic(() => import('./TitleWithLinks')),
  two_columns_image_text: dynamic(() => import('./TwoColumnsImageText')),
  video: dynamic(() => import('./Video')),
  visual_topics_chips: dynamic(() => import('./VisualTopicsChips'))
};
