import SkeletonItem from '../../SkeletonItem';
import ContentContainer from 'components/content-container';
import cx from 'classnames';
import { HomePageAds, LazyLoadAd } from 'components/ads';

export const HorizontalCardSkeleton = ({
  classes: { ribbonClasses },
  isMobileWidth
}) => (
  <ContentContainer className="relative pb-12">
    <div className="grid gap-8 sm-tui:grid-cols-1 lg-tui:grid-cols-3">
      <div className="col-span-2">
        <div className="flex items-center justify-between border-t-thin border-black pt-3">
          <SkeletonItem className="h-4 w-[150px]" />
          {!isMobileWidth && <SkeletonItem className="h-4 w-[150px]" />}
        </div>
        <div className={ribbonClasses}>
          {[...Array(3)].map((_item, index: number) => {
            const cardClasses = cx('w-full', {
              'grid grid-cols-2 gap-6 pt-6': !isMobileWidth
            });

            return (
              <div className={cardClasses} key={index}>
                <div className="order-2">
                  <SkeletonItem className="h-[250px] w-full" />
                </div>
                <div className="order-1">
                  <SkeletonItem className="mb-3 mt-3 h-4 w-[200px]" />
                  <SkeletonItem className="mb-3 h-8 w-full" />
                  <SkeletonItem className="mb-3 h-8 w-full" />
                  <SkeletonItem className="mb-3 h-4 w-[200px]" />
                </div>
              </div>
            );
          })}
        </div>

        {isMobileWidth && (
          <div>
            <div className="my-6 border-t-thin border-[#E5E7EB]" />
            <div className="flex w-full justify-end">
              <div className="flex w-full justify-end">
                <SkeletonItem className="h-4 w-[150px]" />
              </div>
            </div>
          </div>
        )}
      </div>

      <LazyLoadAd>
        <HomePageAds.HomeAdInlineSky />
      </LazyLoadAd>
    </div>
  </ContentContainer>
);

export default HorizontalCardSkeleton;
