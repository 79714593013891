import { EditorialGridItem } from '../EditorialGridItem';
import { SlotData } from '../EditorialGrid.props';

const EditorialThreeColumns = ({
  primary,
  secondary,
  tertiary,
  mixpanelTitle
}: SlotData) => {
  return (
    <>
      {/* 1280 - 1024 */}
      <div
        id="editorial-grid-three-columns"
        data-testid="editorial-grid-three-columns"
        className="invisible grid h-0 grid-cols-12 gap-5 divide-x-thin lg-tui:visible lg-tui:h-min"
      >
        <div className="col-span-5">
          <EditorialGridItem
            slot="primary"
            data={primary}
            contentIndex={1}
            mixpanelTitle={mixpanelTitle}
          />
        </div>
        <div className="col-span-4 pl-5">
          <EditorialGridItem
            slot="secondary"
            data={secondary}
            contentIndex={2}
            mixpanelTitle={mixpanelTitle}
          />
        </div>
        <div
          id="editorial-grid-item-tertiary"
          data-testid="editorial-grid-item-tertiary"
          className="col-span-3 pl-5"
        >
          {tertiary.slice(0, 3).map((item, index) => (
            <EditorialGridItem
              contentIndex={index + 3}
              key={item.slug}
              slot="tertiary"
              wrapperClassName="flex flex-col py-4 first:pt-0 border-t-thin first:border-t-[0]"
              data={item}
              mixpanelTitle={mixpanelTitle}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default EditorialThreeColumns;
