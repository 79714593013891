import AnimatedIconButton from 'components/AnimatedIconButton';
import { formatDistanceToNowStrict } from 'date-fns';
import { useAuthenticated } from 'lib/auth';
import { useIntl } from 'react-intl';

import { Badge, Text } from 'components/@tedui';
import Image from 'components/image';
import { Link } from 'components/router';
import { getBadgeText } from 'components/talk-card/utils';
import { ViewCount } from 'lib/business';
import { toLower } from 'lodash-es';

import type { JSX } from 'react';

const ImageHeroWithRibbonItem = ({
  talk,
  index,
  ribbonTalkLink,
  imageSrc,
  sectionTitle,
  classes: { ribbonCardsClasses },
  trackClick,
  mixpanelTitle
}): JSX.Element => {
  const { formatNumber } = useIntl();
  const loggedIn = useAuthenticated();

  const formattedTime = Math.floor(talk.video?.duration / 60);
  const topicName = talk.video?.topics?.nodes[0]?.name;
  const topicLink = `/talks?sort=relevance&${
    toLower(sectionTitle) === 'ted-ed'
      ? `topics%5B0%5D=ted-ed&topics%5B1%5D=${topicName}`
      : `topics%5B0%5D=${topicName}`
  }`;

  const badgeText = talk.video && getBadgeText(talk.video.type?.name || '');

  return (
    <div className={ribbonCardsClasses} key={`${talk.video?.slug}-${index}`}>
      <Link
        href={`${ribbonTalkLink}`}
        isZenithLink
        className="order-2"
        onClick={() => trackClick('image', index + 2)}
      >
        <div className="relative">
          <div className="aspect-h-9 aspect-w-16">
            <Image
              fill
              className="object-cover"
              src={imageSrc}
              alt={talk.video?.slug}
            />
          </div>

          {badgeText && (
            <div className={'absolute bottom-[0.6rem] left-2'}>
              <Badge
                text={badgeText.large}
                testId="badge--bottomLeft"
                isDarkBackground={false}
                backgroundColor="white"
                UNSAFE_className="!rounded-xs"
              />
            </div>
          )}

          <Text
            variant="unset"
            UNSAFE_className="text-tui-2xs text-white absolute bottom-1 right-2 font-bold bg-textSecondary-onLight px-1 py-1 rounded-xs uppercase sm:leading-[10.6px] sm:tracking-[0.091px]"
            tag="p"
          >
            {formattedTime} min
          </Text>
        </div>
      </Link>
      <div className="order-1">
        <div className="flex items-center justify-between sm-tui:mt-0 md-tui:mt-3">
          <Link
            href={topicLink}
            isZenithLink
            onClick={() => trackClick('topic', index + 2)}
          >
            <Text
              variant="label1"
              useNewTextStyles
              UNSAFE_className="uppercase font-semibold"
              color={{ override: '#EB0028' }}
            >
              {topicName}
            </Text>
          </Link>
          {loggedIn && (
            <AnimatedIconButton
              talk={talk.video}
              mixpanelTitle={mixpanelTitle}
              itemNumber={index + 2}
            />
          )}
        </div>

        <Link
          href={`${ribbonTalkLink}&utm_term=video-title`}
          isZenithLink
          onClick={() => trackClick('title', index + 2)}
        >
          <Text
            tag="p"
            weight="font-semibold"
            variant="subheader2"
            useNewTextStyles
            UNSAFE_className="mt-2"
          >
            {talk.video?.title}
          </Text>

          <div className="mt-2">
            <Text
              tag="p"
              variant="caption1"
              useNewTextStyles
              color={{
                color: 'tertiary'
              }}
            >
              <>{talk.video?.presenterDisplayName} · </>
              <ViewCount count={talk.video!.viewedCount}>
                {formatNumber(talk.video!.viewedCount, {
                  notation: 'compact',
                  compactDisplay: 'short'
                })}{' '}
                plays ·{' '}
              </ViewCount>
              {formatDistanceToNowStrict(new Date(talk.video!.publishedAt))} ago
            </Text>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ImageHeroWithRibbonItem;
